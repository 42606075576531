

import './fook.css';

import web3 from 'web3';

import React, { useEffect, useState } from "react";

import { ethers } from "ethers";

import factoryABI from "./ABIfook-factory.json";
import marketABI from "./ABIfook-market.json";
import tokenABIFOOKS from "./ABIfook-tokenFOOKS.json";
import tokenABIWSGB from "./ABIfook-tokenWSGB.json";

import './rodal.css';
//____________________________________________
//use effect refreshes components before calling functions [order isn't important]





class FookTokens extends React.Component {
    constructor(props) {
        super(props);
        this.state = { visible: false };
    }

    render() {
        return (
            <div className="App">
                <MyFunctions />
            </div>
        );
    }

}


function MyFunctions() {


    //________________________________________________________________________________________
    //MISC VARIABLES
    const [connected, setConnected] = useState(false);
    const [currentAddress, setcurrentAddress] = useState(false);

    const [state, setState] = useState(true);
    const [whoisowner, setOwner] = useState(null);

    const [currentAccount, setCurrentAccount] = useState(null);
    const [chainId, setChainId] = useState(null);
    const [chainNameCard, setChainNameCard] = useState(localStorage.getItem('chainNameCard'));
    const [balance, setBalance] = useState(null);
    const [blockNumber, setBlockNumber] = useState(null);

    const [showMenu, setshowMenu] = useState(true);

    //Overlay Variables
    const [showApproveButton, setshowApproveButton] = useState(false);
    const [showApproveMessages, setshowApproveMessages] = useState('');
    const [showDYKmessage, setshowDYKmessage] = useState('');
    const [zIndexState, setZIndexState] = useState(-1);
    const [AIndexState, setAIndexState] = useState(-1);
    const [StartIndexState, setStartIndexState] = useState(100);

    //submission variables
    const [transactionHash, setTransactionHash] = useState(null);
    const [result, setResult] = useState('');
    const [error, setError] = useState('');
    const [transactionLink, settransactionLink] = useState('');
    const [resultMessage, setresultMessage] = useState('');

    //flare wait message
    const [soonFLR, setsoonFLR] = useState(null);
    //payments
    const [payFeeSGB, setpayFeeSGB] = useState(ethers.utils.parseUnits("502"));
    //const [payFeeFOOKS, setpayFeeFOOKS] = useState(ethers.utils.parseUnits("6000"));



    let str = window.location.pathname
    let parts = str.split("/");
    let fook_type = parts[2];
    let fook_network = parts[3];
    let fook_number = parts[4];


    //________________________________________________________________________________________
    //DARK MODE
    const getDefaultMode = () => {
        const savedMode = localStorage.getItem('mode');
        return savedMode ? savedMode : 'WhiteMode';
    };

    const [mode, setMode] = useState(getDefaultMode());

    const handleToggleDarkMode = () => {
        setMode(mode === localStorage.getItem('mode'));
        if (mode === 'WhiteMode') {
            document.body.classList.add('DarkMode');
            localStorage.setItem('mode', 'DarkMode');
            //document.getElementById("darkMode").innerHTML = "glow";
        } else {
            document.body.classList.add('WhiteMode');
            localStorage.setItem('mode', 'WhiteMode');
            //document.getElementById("darkMode").innerHTML = "dark";
        }
    };


    //________________________________________________________________________________________
    //use effect refreshes components before calling functions [order isn't important]
    useEffect(() => {
        //console.log("mounted");

        return () => {
            //console.log("unmounted");

        };
    }, []);

    const delay = ms => new Promise(res => setTimeout(res, ms));
    const waitFunction = async () => {
        await delay(1000);
    };


    //________________________________________________________________________________________
    //CONTRACT VARIABLES

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();

    const useFooksTokenSGB = new ethers.Contract("0xCfeE4191AE34127b1892617003bbD204CCf3BA27", tokenABIFOOKS, signer);
    const FooksTokenSGB = "0xCfeE4191AE34127b1892617003bbD204CCf3BA27";

    const useFooksTokenFLR = new ethers.Contract("0x22515AE550DC8963D1Eba09C7cE0c291128438E4", tokenABIFOOKS, signer);
    const FooksTokenFLR = "0x22515AE550DC8963D1Eba09C7cE0c291128438E4";

    const useTokenWSGB = new ethers.Contract("0x02f0826ef6aD107Cfc861152B32B52fD11BaB9ED", tokenABIWSGB, signer);
    const TokenWSGB = "0x02f0826ef6aD107Cfc861152B32B52fD11BaB9ED";

    //coston network contracts
    const useFactoryCostonSignN = new ethers.Contract("0xE6AFDB1e5D8250a5bf505Fc727C822230D7E036e", factoryABI, provider);
    const useFactoryCostonSignY = new ethers.Contract("0xE6AFDB1e5D8250a5bf505Fc727C822230D7E036e", factoryABI, signer);
    const useMarketCoston = new ethers.Contract("0x", marketABI, signer);
    const FooksMarketCoston = "0x";
    //songbird network contracts
    const useFactorySGBSignN = new ethers.Contract("0xE6AFDB1e5D8250a5bf505Fc727C822230D7E036e", factoryABI, provider);
    const useFactorySGBSignY = new ethers.Contract("0xE6AFDB1e5D8250a5bf505Fc727C822230D7E036e", factoryABI, signer);
    const useMarketSGB = new ethers.Contract("0xC6749CD67519B3ed0919Be2B7CE9E3bBc63D1BeF", marketABI, signer);
    const FooksMarketSGB = "0xC6749CD67519B3ed0919Be2B7CE9E3bBc63D1BeF";
    //flare network contracts
    const useFactoryFLRSignN = new ethers.Contract("0xE6AFDB1e5D8250a5bf505Fc727C822230D7E036e", factoryABI, provider);
    const useFactoryFLRSignY = new ethers.Contract("0xE6AFDB1e5D8250a5bf505Fc727C822230D7E036e", factoryABI, signer);
    const useFooksMarketFLR = new ethers.Contract("0x", marketABI, signer);
    const FooksMarketFLR = "0x";


    //________________________________________________________________________________________
    //NETWORK VARIABLES
    const switchNetworkCoston = async () => {
        //reset the local variable for network switches (so they only show briefly when switched)
        localStorage.setItem('chainNameCard', '');

        //switch to correct network, if needed
        if (window.ethereum.networkVersion !== '16') {
            switchtoCoston();
        }
    }
    const switchNetworkSGB = async () => {
        //reset the local variable for network switches (so they only show briefly when switched)
        localStorage.setItem('chainNameCard', '');

        //switch to correct network, if needed
        if (window.ethereum.networkVersion !== '19') {
            switchtoSGB();
        }
    }
    const switchNetworkFLR = async () => {
        //reset the local variable for network switches (so they only show briefly when switched)
        localStorage.setItem('chainNameCard', '');

        //switch to correct network, if needed
        if (window.ethereum.networkVersion !== '14') {
            switchtoFLR();
        }
    }
    //____________________________________________
    //change networks
    const switchtoCoston = async () => {
        const chainId = 16 // 16 is Coston [SGB]
        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: web3.utils.toHex(chainId) }]
            });

            //store the new chain name in local storage to show user
            localStorage.setItem('chainNameCard', 'you were switched to Coston');

        } catch (err) {
            // This error code indicates that the chain has not been added to MetaMask
            if (err.code === 4902) {
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainName: 'Coston Test Network',
                            chainId: web3.utils.toHex(chainId),
                            nativeCurrency: { name: 'Coston Test Network', decimals: 18, symbol: 'CFLR' },
                            rpcUrls: ['https://coston-api.flare.network/ext/bc/C/rpc'],
                            blockExplorerUrls: ['https://coston-explorer.flare.network/']
                        }]
                });
            }
            switchNetworkCoston();
        }
    }

    const switchtoSGB = async () => {
        const chainId = 19 // 19 is SGB; change to 16 when using Coston
        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: web3.utils.toHex(chainId) }]
            });

            //store the new chain name in local storage to show user
            localStorage.setItem('chainNameCard', 'you were switched to Songbird');

        } catch (err) {
            // This error code indicates that the chain has not been added to MetaMask
            if (err.code === 4902) {
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainName: 'Songbird',
                            chainId: web3.utils.toHex(chainId),
                            nativeCurrency: { name: 'Songbird', decimals: 18, symbol: 'SGB' },
                            rpcUrls: ['https://songbird-api.flare.network/ext/C/rpc'],
                            blockExplorerUrls: ['https://songbird-explorer.flare.network/']
                        }]
                });
            }
            switchNetworkSGB();
        }
    }

    const switchtoFLR = async () => {
        const chainId = 14 // Flare Network Id
        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: web3.utils.toHex(chainId) }]
            });

            //store the new chain name in local storage to show user
            localStorage.setItem('chainNameCard', 'you were switched to Flare');

        } catch (err) {
            // This error code indicates that the chain has not been added to MetaMask
            if (err.code === 4902) {
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainName: 'Flare',
                            chainId: web3.utils.toHex(chainId),
                            nativeCurrency: { name: 'FLARE', decimals: 18, symbol: 'FLR' },
                            rpcUrls: ['https://flare-api.flare.network/ext/C/rpc'],
                            blockExplorerUrls: ['https://flare-explorer.flare.network/']
                        }]
                });
            }
            switchNetworkFLR();
        }
    }//end 'switch to other network' functions


    //________________________________________________________________________________________
    //APPROVAL LAYER
    const showApprovalLayer = async () => {
        setZIndexState(1);
        setshowApproveButton(true);
    };
    const hideApprovalLayer = async () => {
        if (!state) {
            window.open('/', '_SELF');
        } else {
            setZIndexState(-1);
            setshowApproveButton(false);
        }
    };


    //________________________________________________________________________________________
    //HIJACKED
    const sendData = async () => {
    };
    const getData = async () => {
        setOwner(whoisowner);
    }
    const getWalletAddress = async () => {
        if (window.ethereum && window.ethereum.isMetaMask) {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            await provider.send("eth_requestAccounts");
            const currentAddress = await signer.getAddress();

            setCurrentAccount(currentAddress)
            const chain = await provider.getNetwork();
            setChainId(chain.chainId);
            const amount = await provider.getBalance(currentAddress);
            const amountInEth = ethers.utils.formatEther(amount);
            const blockNumber = await provider.getBlockNumber();
            setBlockNumber(blockNumber);
        }
    }
    const chainChanged = () => {
        window.location.reload();
    }
    window.ethereum.on('chainChanged', chainChanged);
    window.ethereum.on('accountsChanged', chainChanged)



    //_________________________________________________________________________________
    //PAYMENT BUTTONS


    const switchandBuySGB = async () => {
        //clear soon FLR message, in case it's showing
        setsoonFLR('');

        // Connect the wallet using ethers.js
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        await provider.send('eth_requestAccounts', []); // <- this promps user to connect metamask
        const signer = await provider.getSigner();
        const currentAddress = await signer.getAddress();
        setConnected(true);
        setcurrentAddress(currentAddress);

        //switch to correct network, if needed
        switchNetworkSGB();
        //let's try to show overlay when using buttons
        setshowApproveMessages('please approve the transaction...')
        setshowDYKmessage("did you know? \n\n\n you can save money using FOOKS tokens; buy some at https://flarefooks.com/fook-tokens ")
        showApprovalLayer();


            //check for sufficient funds
        const FOOKSbalance = await provider.getBalance(currentAddress);
        if (ethers.utils.formatEther(FOOKSbalance) < ethers.utils.formatEther(payFeeSGB)) {
                //user does not have the funds
                setshowApproveMessages('you do not have enough unwrapped SGB tokens...')
                setshowDYKmessage("did you know? \n\n\n you can save money using FOOKS tokens; buy some at https://flarefooks.com/fook-tokens ")
                showApprovalLayer();
            } else {
                //user does have the funds
                //await provider.send("eth_requestAccounts");
                if (useMarketSGB) {
                    const buywithSGB = await useMarketSGB.buyTokens({
                        value: payFeeSGB,
                        gasLimit: 200000
                        }
                    );
                    if (buywithSGB.hash === '') {
                        //the transaction was not successul
                        setTransactionHash('');
                    } else {
                        //the transaction was successul
                        setTransactionHash(buywithSGB.hash);
                        localStorage.setItem('receiptHash', transactionHash);
                        settransactionLink('https://songbird-explorer.flare.network/tx/' + buywithSGB.hash);
                        //setresultMessage("you now have enough to purchase [how many depending on type");
                        hideApprovalLayer();
                    }
                }
            }
        

    }//end 'buy with SGB' function


    const switchandBuyFLR = async () => {  

     //end function
    }

//____________________________________________
//html
    return (
    <div>

        <div id="helpMode" className="btn-on" onClick={() => window.close()}>back</div>
        <div id="darkMode" className="btn-on" onClick={() => handleToggleDarkMode()}>dark</div>

        <div className='chainName'>{chainNameCard}</div>


            <div className="start-block">
                <div id="BUYmenu" className={`slide-upMenu ${showMenu ? 'show' : 'hide'}`}>
                    <div id="menu-options" >
                        |&nbsp;&nbsp;&nbsp;<a id="menu-FLR-fooks" onClick={() => setsoonFLR("sorry, we're still testing on SongBird [as good developers should!]")}>fooks on Flare</a>&nbsp;&nbsp;
                        |&nbsp;&nbsp;&nbsp;<a id="menu-SGB-fooks" onClick={() => switchandBuySGB()}>fooks on SongBird</a>&nbsp;&nbsp;&nbsp;|
                    </div>
                </div>

                <div className="font-title">flare &nbsp;
                    <div id="flare-f">f</div>ooks
                </div>
           </div>



            <div>
                <div>{resultMessage}</div>
                <br />
                <a href={result} target="_blank" rel="noreferrer">{result}</a>
                <br />
                <br />
                <br />
                <div>
                </div>
                <a href={transactionLink} target="_blank" rel="noreferrer">{transactionHash}</a>

                {soonFLR}
            </div>

        
        
            <div style={{ zIndex: zIndexState }} className="back-Mainoverlay" className={`back-Mainoverlay ${showApproveButton ? 'show' : 'hide'}`}>
                <div id="approval-objects" >
                    <div className="approveMessage">{showApproveMessages}</div>
                    <br />
                    <div id="goBackbutton" className="payButtons" onClick={() => hideApprovalLayer()}>GO BACK</div>
                    <div className="DYKmessage">{showDYKmessage}</div>
                </div>
            </div>
        
               
        
        </div>
    );
}

export default FookTokens;