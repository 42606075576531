

import logo from './logo.svg';
import './fook.css';
import './fook-card.css';
import FookCard from './fook-card.js';

import web3 from 'web3';

import React, { useEffect, useState, useCallback } from "react";
import ReactDOM from "react-dom";
import {useNavigate} from 'react-router-dom';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { ethers } from "ethers";

import { GoogleFont } from 'react-google-fonts';

import Select from "react-select";

import Rodal from 'rodal';
import './rodal.css';
import axios from 'axios';
//____________________________________________
//use effect refreshes components before calling functions [order isn't important]





class FookLink extends React.Component {
    constructor(props) {
        super(props);
        this.state = { visible: false };
    }

    render() {
        return (
            <div className="App">
                <MyFunctions />
            </div>
        );
    }

}


function MyFunctions() {

//________________________________________________________________________________________
//DARK MODE
    const getDefaultMode = () => {
        const savedMode = localStorage.getItem('mode');
        return savedMode ? savedMode : 'WhiteMode';
    };

    const [mode, setMode] = useState(getDefaultMode());

    const handleToggleDarkMode = () => {
        setMode(mode === localStorage.getItem('mode'));
        if (mode === 'WhiteMode') {
            document.body.classList.add('DarkMode');
            localStorage.setItem('mode', 'DarkMode');
            //document.getElementById("darkMode").innerHTML = "glow";
        } else {
            document.body.classList.add('WhiteMode');
            localStorage.setItem('mode', 'WhiteMode');
            //document.getElementById("darkMode").innerHTML = "dark";
        }
    };

//____________________________________________
//use effect refreshes components before calling functions [order isn't important]
    useEffect(() => {

    }, [mode]);



    const redirectANDclearswitch = () => {
        localStorage.setItem('chainNameCard', '');
        window.open('/', '_SELF');
    }


//____________________________________________
//html
    return (
    <div>

        <FookCard />

            <div id="helpMode" className="btn-on" onClick={() => redirectANDclearswitch()}>home</div>
            <div id="darkMode" className="btn-on" onClick={() => handleToggleDarkMode()}>dark</div>
        
        
        
        
        
        
        
        
        
        
        </div>
    );
}

export default FookLink;