

import logo from './logo.svg';
import './fook.css';
import FookCard from './fook-card.js';

import React, { useEffect, useState } from "react";

import { ethers } from "ethers";
import web3 from 'web3';

import Select from "react-select";

import factoryABI from "./ABIfook-factory.json";
import marketABI from "./ABIfook-market.json";
import tokenABIFOOKS from "./ABIfook-tokenFOOKS.json";
import tokenABIWSGB from "./ABIfook-tokenWSGB.json";

import Rodal from 'rodal';
import './rodal.css';

import $ from "jquery";

//____________________________________________
//use effect refreshes components before calling functions [order isn't important]





class Fook extends React.Component {
    constructor(props) {
        super(props);
        this.state = { visible: false };
    }
    show() {
        this.setState({ visible: true });
    }
    hide() {
        this.setState({ visible: false });
    }

    render() {
        return (
            <div className="App">
                <div id="helpMode" className="btn-on" onClick={this.show.bind(this)}>view</div>
                <Rodal visible={this.state.visible} onClose={this.hide.bind(this)}>
                    <FookCard />
                </Rodal>
                <MyFunctions />
            </div>
        );
    }
}

function MyFunctions() {


    //________________________________________________________________________________________
    //MISC VARIABLES
    const [state, setState] = useState(true);

    const [chainNameCard, setChainNameCard] = useState(localStorage.getItem('chainNameCard'));

    const [showMenu, setshowMenu] = useState(false);
    const [showMenuOptions, setshowMenuOptions] = useState(false);
    const [showGenerator, setshowGenerator] = useState(false);
    const [showMenucomingSoon, setshowMenucomingSoon] = useState(false);
    //const [showGenForm, setshowGenForm] = useState(false);

    const [showGenSelector, setshowGenSelector] = useState(false);
    //form variables
    const [showGentype, setshowGentype] = useState('');
    const [showGenemail, setshowGenemail] = useState(false);
    const [showGenname, setshowGenname] = useState(false);
    const [showGentitle, setshowGentitle] = useState(false);
    const [showGendescription, setshowGendescription] = useState(false);
    const [showGenwebsite, setshowGenwebsite] = useState(false);
    const [showGencontract, setshowGencontract] = useState(false);
    const [showGenrecipient, setshowGenrecipient] = useState(false);
    const [showGentoken1, setshowGentoken1] = useState(false);
    const [showGenamount1, setshowGenamount1] = useState(false);
    const [showGentoken2, setshowGentoken2] = useState(false);
    const [showGenamount2, setshowGenamount2] = useState(false);
    const [showGentoken3, setshowGentoken3] = useState(false);
    const [showGenamount3, setshowGenamount3] = useState(false);
    const [showGentoken4, setshowGentoken4] = useState(false);
    const [showGenamount4, setshowGenamount4] = useState(false);
    const [showGentags, setshowGentags] = useState(false);
    const [showGenimage, setshowGenimage] = useState(false);

    const [showPreviewButton, setshowPreviewButton] = useState(false);
    const [showGensubmit, setshowGensubmit] = useState(false);

    const [showGensoon, setshowGensoon] = useState(false);
    const [showGenpayButtons, setshowGenpayButtons] = useState(false);
    const [showGensuccess, setshowGensuccess] = useState(false);
    //AI message
    const [showWelcome, setshowWelcome] = useState(true);
    const [showstartButton, setshowstartButton] = useState(false);
    const [showFOOKSamt, setshowFOOKSamt] = useState(false);

    const [inputValue, setInputValue] = useState('');

    const [connected, setConnected] = useState(false);
    const [currentAddress, setcurrentAddress] = useState(false);

    //Fees
    const [payDonationFeeWSGB, setpayDonationFeeWSGB] = useState(ethers.utils.parseUnits("2000"));
    const [payDonationFeeFOOKS, setpayDonationFeeFOOKS] = useState(ethers.utils.parseUnits("2000"));
    //Overlay Variables
    const [showApproveButton, setshowApproveButton] = useState(false);
    const [showApproveMessages, setshowApproveMessages] = useState('');
    const [showDYKmessage, setshowDYKmessage] = useState('');
    const [zIndexState, setZIndexState] = useState(-1);
    const [AIndexState, setAIndexState] = useState(-1);
    const [StartIndexState, setStartIndexState] = useState(100);

    //submission variables
    const [transactionHash, setTransactionHash] = useState(null);
    const [result, setResult] = useState('');
    const [error, setError] = useState('');
    const [transactionLink, settransactionLink] = useState('');
    const [resultMessage, setresultMessage] = useState('');


    const [whoisowner, setOwner] = useState(null);

    const [currentAccount, setCurrentAccount] = useState(null);
    const [chainId, setChainId] = useState(null);
    const [blockNumber, setBlockNumber] = useState(null);

    //fooks balance
    const [balance, setBalance] = useState(null);

    //________________________________________________________________________________________
    //DARK MODE
    const getDefaultMode = () => {
        const savedMode = localStorage.getItem('mode');
        return savedMode ? savedMode : 'WhiteMode';
    };

    const [mode, setMode] = useState(getDefaultMode());

    const handleToggleDarkMode = () => {
        setMode(mode === localStorage.getItem('mode'));
        if (mode === 'WhiteMode') {
            document.body.classList.add('DarkMode');
            localStorage.setItem('mode', 'DarkMode');
            //document.getElementById("darkMode").innerHTML = "glow";
        } else {
            document.body.classList.add('WhiteMode');
            localStorage.setItem('mode', 'WhiteMode');
            //document.getElementById("darkMode").innerHTML = "dark";
        }
    };


    //________________________________________________________________________________________
    //use effect refreshes components before calling functions [order isn't important]
    useEffect(() => {
        localStorage.setItem('help-fook_contract', '');
        localStorage.setItem('submit_fookTitles', '');
        localStorage.setItem('submit_fookTokens', '');
        localStorage.setItem('submit_fookLinks', '');

        localStorage.setItem('help-fook_title', "title of your fook appears here \n[max 58 characters]");
        localStorage.setItem('help-fook_name', 'fook name');
        localStorage.setItem('help-fook_recipient', 'address where the crypto is sent will display here');
        localStorage.setItem('help-fook_description', 'the description details you enter will display here \n[max 1000 characters]');
        localStorage.setItem('help-fook_website', 'website you provide will appear here \n[optional: max 1000 characters]');

        localStorage.setItem('help-fook_amount1', '1000');
        localStorage.setItem('help-fook_token1', 'FOOKS');
        localStorage.setItem('help-fook_amount2', '20000');
        localStorage.setItem('help-fook_token2', 'FOOKS');
        localStorage.setItem('help-fook_amount3', '5000');
        localStorage.setItem('help-fook_token3', 'FOOKS');

        localStorage.setItem('help-fook_imagelink', '');

        waitFunction();
        //end use effect
    }, [mode]);

    const delay = ms => new Promise(res => setTimeout(res, ms));
    const waitFunction = async () => {
        if (localStorage.getItem('AImessageShown') === 'yes') {
            await delay(1000);
            //AI message
            setshowWelcome(false);
            setshowstartButton(true);
            setshowFOOKSamt(true);
            getFOOKSbalance();
        } else {
            await delay(3000);
            localStorage.setItem('AImessageShown', 'yes');
            //AI message
            setshowWelcome(false);
            setshowstartButton(true);
            setshowFOOKSamt(true);
            getFOOKSbalance();
        }
    };


    //________________________________________________________________________________________
    //CONTRACT VARIABLES
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();

    const useFooksTokenSGB = new ethers.Contract("0xCfeE4191AE34127b1892617003bbD204CCf3BA27", tokenABIFOOKS, signer);
    const FooksTokenSGB = "0xCfeE4191AE34127b1892617003bbD204CCf3BA27";

    const useFooksTokenFLR = new ethers.Contract("0x22515AE550DC8963D1Eba09C7cE0c291128438E4", tokenABIFOOKS, signer);
    const FooksTokenFLR = "0x22515AE550DC8963D1Eba09C7cE0c291128438E4";

    const useTokenWSGB = new ethers.Contract("0x02f0826ef6aD107Cfc861152B32B52fD11BaB9ED", tokenABIWSGB, signer);
    const TokenWSGB = "0x02f0826ef6aD107Cfc861152B32B52fD11BaB9ED";

    //coston network contracts
    const useFactoryCostonSignN = new ethers.Contract("0xE6AFDB1e5D8250a5bf505Fc727C822230D7E036e", factoryABI, provider);
    const useFactoryCostonSignY = new ethers.Contract("0xE6AFDB1e5D8250a5bf505Fc727C822230D7E036e", factoryABI, signer);
    const useMarketCoston = new ethers.Contract("0x", marketABI, signer);
    const FooksMarketCoston = "0x";
    //songbird network contracts
    const useFactorySGBSignN = new ethers.Contract("0x", factoryABI, provider);
    const useFactorySGBSignY = new ethers.Contract("0x", factoryABI, signer);
    const useMarketSGB = new ethers.Contract("0xC6749CD67519B3ed0919Be2B7CE9E3bBc63D1BeF", marketABI, signer);
    const FooksMarketSGB = "0xC6749CD67519B3ed0919Be2B7CE9E3bBc63D1BeF";
    //flare network contracts
    const useFactoryFLRSignN = new ethers.Contract("0x", factoryABI, provider);
    const useFactoryFLRSignY = new ethers.Contract("0x", factoryABI, signer);
    const useFooksMarketFLR = new ethers.Contract("0x", marketABI, signer);
    const FooksMarketFLR = "0x";


    //________________________________________________________________________________________
    //NETWORK VARIABLES
    const switchNetworkCoston = async () => {
        //reset the local variable for network switches (so they only show briefly when switched)
        localStorage.setItem('chainNameCard', '');

        //switch to correct network, if needed
        if (window.ethereum.networkVersion !== '16') {
            switchtoCoston();
        }
    }
    const switchNetworkSGB = async () => {
        //reset the local variable for network switches (so they only show briefly when switched)
        localStorage.setItem('chainNameCard', '');

        //switch to correct network, if needed
        if (window.ethereum.networkVersion !== '19') {
            switchtoSGB();
        }
    }
    const switchNetworkFLR = async () => {
        //reset the local variable for network switches (so they only show briefly when switched)
        localStorage.setItem('chainNameCard', '');

        //switch to correct network, if needed
        if (window.ethereum.networkVersion !== '14') {
            switchtoFLR();
        }
    }
    //____________________________________________
    //change networks
    const switchtoCoston = async () => {
        const chainId = 16 // 16 is Coston [SGB]
        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: web3.utils.toHex(chainId) }]
            });

            //store the new chain name in local storage to show user
            localStorage.setItem('chainNameCard', 'you were switched to Coston');

        } catch (err) {
            // This error code indicates that the chain has not been added to MetaMask
            if (err.code === 4902) {
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainName: 'Coston Test Network',
                            chainId: web3.utils.toHex(chainId),
                            nativeCurrency: { name: 'Coston Test Network', decimals: 18, symbol: 'CFLR' },
                            rpcUrls: ['https://coston-api.flare.network/ext/bc/C/rpc'],
                            blockExplorerUrls: ['https://coston-explorer.flare.network/']
                        }]
                });
            }
            switchNetworkCoston();
        }
    }

    const switchtoSGB = async () => {
        const chainId = 19 // 19 is SGB; change to 16 when using Coston
        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: web3.utils.toHex(chainId) }]
            });

            //store the new chain name in local storage to show user
            localStorage.setItem('chainNameCard', 'you were switched to Songbird');

        } catch (err) {
            // This error code indicates that the chain has not been added to MetaMask
            if (err.code === 4902) {
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainName: 'Songbird',
                            chainId: web3.utils.toHex(chainId),
                            nativeCurrency: { name: 'Songbird', decimals: 18, symbol: 'SGB' },
                            rpcUrls: ['https://songbird-api.flare.network/ext/C/rpc'],
                            blockExplorerUrls: ['https://songbird-explorer.flare.network/']
                        }]
                });
            }
            switchNetworkSGB();
        }
    }

    const switchtoFLR = async () => {
        const chainId = 14 // Flare Network Id
        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: web3.utils.toHex(chainId) }]
            });

            //store the new chain name in local storage to show user
            localStorage.setItem('chainNameCard', 'you were switched to Flare');

        } catch (err) {
            // This error code indicates that the chain has not been added to MetaMask
            if (err.code === 4902) {
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainName: 'Flare',
                            chainId: web3.utils.toHex(chainId),
                            nativeCurrency: { name: 'FLARE', decimals: 18, symbol: 'FLR' },
                            rpcUrls: ['https://flare-api.flare.network/ext/C/rpc'],
                            blockExplorerUrls: ['https://flare-explorer.flare.network/']
                        }]
                });
            }
            switchNetworkFLR();
        }
    }//end 'switch to other network' functions


    //________________________________________________________________________________________
    //APPROVAL LAYER
    const showApprovalLayer = async () => {
        setZIndexState(1);
        setshowApproveButton(true);
    }
    const hideApprovalLayer = async () => {
        if (state === false) {
            window.open('/', '_SELF');
        } else {
            setZIndexState(-1);
            setshowApproveButton(false);
            setshowstartButton(true);
            setshowFOOKSamt(true);
        }
    };




    //________________________________________________________________________________________
    //HIJACKED
    const sendData = async () => {
    };
    const getData = async () => {
        setOwner(whoisowner);
    }
    const getWalletAddress = async () => {
        if (window.ethereum && window.ethereum.isMetaMask) {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            await provider.send("eth_requestAccounts");
            const currentAddress = await signer.getAddress();

            setCurrentAccount(currentAddress);
            const chain = await provider.getNetwork();
            setChainId(chain.chainId);
            const amount = await provider.getBalance(currentAddress);
            const amountInEth = ethers.utils.formatEther(amount);
            const blockNumber = await provider.getBlockNumber();
            setBlockNumber(blockNumber);
        }
    }
    const chainChanged = () => {
        window.location.reload();
    }
    window.ethereum.on('chainChanged', chainChanged);
    window.ethereum.on('accountsChanged', chainChanged)    



    //_________________________________________________________________________________
    //PAYMENT BUTTONS

    const switchandBuywithWSGB = async () => {
        // Connect the wallet using ethers.js
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        await provider.send('eth_requestAccounts', []); // <- this promps user to connect metamask
        const signer = await provider.getSigner();
        const currentAddress = await signer.getAddress();
        setConnected(true);
        setcurrentAddress(currentAddress);

        //switch to correct network, if needed
        switchNetworkSGB();
        //let's try to show overlay when using buttons
        setshowApproveMessages('please approve the transaction...')
        setshowDYKmessage("did you know? \n\n\n you can save money using FOOKS tokens; buy some at https://flarefooks.com/fook-tokens ")
        showApprovalLayer();


        if (window.ethereum.networkVersion === '19') {
            //check for approvals
            const hasAllowance = await useTokenWSGB.allowance(
                currentAddress,
                FooksMarketSGB
            );
            if (hasAllowance > +payDonationFeeWSGB) {
                //check for sufficient funds
                const FOOKSbalance = await useTokenWSGB.balanceOf(currentAddress);
                if (FOOKSbalance < +payDonationFeeWSGB) {
                    //user does not have the funds
                    setshowApproveMessages('you do not have enough tokens...')
                    setshowDYKmessage("did you know? \n\n\n you can save money using FOOKS tokens; buy some at https://flarefooks.com/fook-tokens ")
                    showApprovalLayer();
                } else {
                    //user does have the funds
                    //await provider.send("eth_requestAccounts");
                    if (useMarketSGB) {
                        const buywithSGB = await useMarketSGB.payERC20forCard(
                            TokenWSGB,
                            payDonationFeeWSGB,
                            { gasLimit: 200000 }
                        );
                        if (buywithSGB.hash === '') {
                            //the transaction was not successul
                            setTransactionHash('');
                            setshowGenpayButtons(true);
                            setAIndexState(1);
                        } else {
                            //the transaction was successul
                            setTransactionHash(buywithSGB.hash);
                            localStorage.setItem('receiptHash', transactionHash);
                            hideApprovalLayer();
                            showDonationForm();
                        }
                    }
                }
            } else {
                //user does not have sufficient allowance to send this token
                setshowApproveMessages('please approve the token transfer...')
                setshowDYKmessage("did you know? \n\n\n \'approvals\' do not spend money, you are simply telling the token contract \'I give permission to send up to this amount of tokens any time I click \'SEND\' in my wallet\'")
                showApprovalLayer();
                setshowGenpayButtons(false);
                setAIndexState(-1);
                setshowstartButton(false);
                setshowFOOKSamt(false);
                //await provider.send("eth_requestAccounts");
                if (useTokenWSGB) {
                    const approveToken = await useTokenWSGB.approve(
                        FooksMarketSGB,
                        ethers.utils.parseEther('100000000000000000000000000'),
                        { gasLimit: 200000 }
                    );
                    if (approveToken.hash === '') {
                        //the set allowance transaction was not successful
                    } else {
                        //the set allowance transaction was successful,ask wallet to send
                        setshowApproveMessages('now you can send the tokens...')
                        //check for sufficient funds
                        const FOOKSbalance = await useTokenWSGB.balanceOf(currentAddress);
                        if (FOOKSbalance < +payDonationFeeWSGB) {
                            //user does not have the funds
                            setshowApproveMessages('you do not have enough tokens...')
                            setshowDYKmessage("did you know? \n\n\n you can save money using FOOKS tokens; buy some at https://flarefooks.com/fook-tokens ")
                            showApprovalLayer();
                        } else {
                            //user does have the funds
                            // await provider.send("eth_requestAccounts");
                            if (useMarketSGB) {
                                const buywithSGB = await useMarketSGB.payERC20forCard(
                                    TokenWSGB,
                                    payDonationFeeWSGB,
                                    { gasLimit: 200000 }
                                );
                                if (buywithSGB.hash === '') {
                                    //the transaction was not successul
                                    setTransactionHash('');
                                    setshowGenpayButtons(true);
                                    setAIndexState(1);
                                } else {
                                    setshowApproveButton(false);
                                    //the transaction was successul
                                    setTransactionHash(buywithSGB.hash);
                                    localStorage.setItem('receiptHash', transactionHash);
                                    hideApprovalLayer();
                                    showDonationForm();
                                }
                            }
                        }
                    }
                }
            }
        }//end of the 'if on songbird network 19' line

        if (window.ethereum.networkVersion === '16') {
            hideApprovalLayer();
            showDonationForm();
        }

    }//end 'buy with WSGB' function

    const switchandBuywithFOOKS = async () => {
        // Connect the wallet using ethers.js
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        await provider.send('eth_requestAccounts', []); // <- this promps user to connect metamask
        const signer = await provider.getSigner();
        const currentAddress = await signer.getAddress();
        setConnected(true);
        setcurrentAddress(currentAddress);

        //switch to correct network, if needed
        switchNetworkSGB();
        //let's try to show overlay when using buttons
        setshowApproveMessages('please approve the transaction...')
        setshowDYKmessage("did you know? \n\n\n you can save money using FOOKS tokens; buy some at https://flarefooks.com/fook-tokens ")
        showApprovalLayer();


        if (window.ethereum.networkVersion === '19') {
            //check for approvals
            const hasAllowance = await useFooksTokenSGB.allowance(
                currentAddress,
                FooksMarketSGB
            );
            if (hasAllowance > +payDonationFeeFOOKS) {
                //check for sufficient funds
                const FOOKSbalance = await useFooksTokenSGB.balanceOf(currentAddress);
                if (FOOKSbalance < +payDonationFeeFOOKS) {
                    //user does not have the funds
                    setshowApproveMessages('you do not have enough tokens...')
                    setshowDYKmessage("did you know? \n\n\n you can save money using FOOKS tokens; buy some at https://flarefooks.com/fook-tokens ")
                    showApprovalLayer();
                } else {
                    //user does have the funds
                    //await provider.send("eth_requestAccounts");


                    if (useMarketSGB) {
                        const buywithFOOKS = await useMarketSGB.payERC20forCard(
                            FooksTokenSGB,
                            payDonationFeeFOOKS,
                            { gasLimit: 200000 }
                        );
                        if (buywithFOOKS.hash === '') {
                            //the transaction was not successul
                            setTransactionHash('');
                            setshowGenpayButtons(true);
                            setAIndexState(1);
                        } else {
                            //the transaction was successul
                            setTransactionHash(buywithFOOKS.hash);
                            localStorage.setItem('receiptHash', transactionHash);
                            hideApprovalLayer();
                            showDonationForm();
                        }
                    }
                }
            } else {
                //user does not have sufficient allowance to send this token
                setshowApproveMessages('please approve the token transfer...')
                setshowDYKmessage("did you know? \n\n\n \'approvals\' do not spend money, you are simply telling the token contract \'I give permission to send up to this amount of tokens any time I click \'SEND\' in my wallet\'")
                showApprovalLayer();
                setshowGenpayButtons(false);
                setAIndexState(-1);
                setshowstartButton(false);
                setshowFOOKSamt(false);
                //await provider.send("eth_requestAccounts");
                if (useFooksTokenSGB) {
                    const approveToken = await useFooksTokenSGB.approve(
                        FooksMarketSGB,
                        ethers.utils.parseEther('100000000000000000000000000'),
                        { gasLimit: 200000 }
                    );
                    if (approveToken.hash === '') {
                        //the set allowance transaction was not successful
                    } else {
                        //the set allowance transaction was successful,ask wallet to send
                        setshowApproveMessages('now you can send the tokens...')
                        //check for sufficient funds
                        const FOOKSbalance = await useFooksTokenSGB.balanceOf(currentAddress);
                        if (FOOKSbalance < +payDonationFeeFOOKS) {
                            //user does not have the funds
                            setshowApproveMessages('you do not have enough tokens...')
                            setshowDYKmessage("did you know? \n\n\n you can save money using FOOKS tokens; buy some at https://flarefooks.com/fook-tokens ")
                            showApprovalLayer();
                        } else {
                            //user does have the funds
                            //await provider.send("eth_requestAccounts");
                            if (useMarketSGB) {
                                const buywithFOOKS = await useMarketSGB.payERC20forCard(
                                    FooksTokenSGB,
                                    payDonationFeeFOOKS,
                                    { gasLimit: 200000 }
                                );
                                if (buywithFOOKS.hash === '') {
                                    //the transaction was not successul
                                    setTransactionHash('');
                                    setshowGenpayButtons(true);
                                    setAIndexState(1);
                                } else {
                                    setshowApproveButton(false);
                                    //the transaction was successul
                                    setTransactionHash(buywithFOOKS.hash);
                                    localStorage.setItem('receiptHash', transactionHash);
                                    hideApprovalLayer();
                                    showDonationForm();
                                }
                            }
                        }
                    }
                }
            }
        }//end of the 'if on songbird network 19' line

        if (window.ethereum.networkVersion === '16') {
            hideApprovalLayer();
            showDonationForm();
        }

    }//end 'buy with FOOKS' function



    //________________________________________________________________________________________
    //FIELD VARIABLES SHOW/HIDE
    const showFormPayButtons = () => {
        //_____
        setshowGenSelector(false); setshowGenerator(true); setshowGensoon(false);
        //_____
        setshowGenpayButtons(true); setAIndexState(1);
        //_____
        setshowGenemail(false); setshowGenname(false); setshowGentitle(false); setshowGendescription(false);
        setshowGenwebsite(false); setshowGencontract(false); setshowGenrecipient(false); setshowGentoken1(false);
        setshowGenamount1(false); setshowGentoken2(false); setshowGenamount2(false); setshowGentoken3(false);
        setshowGenamount3(false); setshowGentoken4(false); setshowGenamount4(false); setshowGentags(false);
        setshowGenimage(false);
        //_____
        setshowPreviewButton(false); setshowGensubmit(false);
        //_____
        setshowGensuccess(false);
    }
    //____________________________________________
    const showDonationForm = () => {
        //_____
        setshowGenSelector(false); setshowGenerator(true); setshowGensoon(false);
        //_____
        setshowGenpayButtons(false); setAIndexState(-1);
        //_____
        setshowGenemail(true); setshowGenname(true); setshowGentitle(true); setshowGendescription(true);
        setshowGenwebsite(true); setshowGencontract(false); setshowGenrecipient(true); setshowGentoken1(true);
        setshowGenamount1(true); setshowGentoken2(true); setshowGenamount2(true); setshowGentoken3(true);
        setshowGenamount3(true); setshowGentoken4(true); setshowGenamount4(true); setshowGentags(true);
        setshowGenimage(true);
        //_____
        setshowPreviewButton(true); setshowGensubmit(false);
        //_____
        setshowGensuccess(false)
    }
    //____________________________________________
    const showFookLinkSuccess = () => {
        //_____
        setshowGenSelector(false); setshowGenerator(false); setshowGensoon(false);
        //_____
        setshowGenpayButtons(false); setAIndexState(-1);
        //_____
        setshowGenemail(false); setshowGenname(false); setshowGentitle(false); setshowGendescription(false);
        setshowGenwebsite(false); setshowGencontract(false); setshowGenrecipient(false); setshowGentoken1(false);
        setshowGenamount1(false); setshowGentoken2(false); setshowGenamount2(false); setshowGentoken3(false);
        setshowGenamount3(false); setshowGentoken4(false); setshowGenamount4(false); setshowGentags(false);
        setshowGenimage(false);
        //_____
        setshowPreviewButton(false); setshowGensubmit(false);
        //_____
        setshowGensuccess(true)
    }


    //____________________________________________
    //fook selector
    const [selectedOption, setSelectedOption] = useState("");
    const options = [
        { value: "", label: "...select a fook" },
        //{ value: "delegate", label: "delegate" },
        { value: "donate", label: "donate" },
        //{ value: "mint", label: "mint" },
        //{ value: "pay", label: "pay" },
        //{ value: "wrap", label: "wrap" },
    ];

    const handleTypeSelect = (e) => {
        setSelectedOption(e.value);
        setshowGentype(e.value);

            showFormPayButtons();
    };
    //____________________________________________
    //token selectors
    const [selectedToken1, setselectedToken1] = useState("");
    const tokenOptions1 = [
        { value: "", label: "...select a token" },
        { value: "FOOKS", label: "FOOKS" },
        //{ value: "SGB", label: "SGB" },
        { value: "WSGB", label: "WSGB" },
        //{ value: "FLR", label: "FLR" },
        //{ value: "WFLR", label: "WFLR" },
    ];
    const handleTokenSelect1 = (e) => {
        setselectedToken1(e.value);
        localStorage.setItem('help-fook_token1', e.value);
    };
    const [selectedToken2, setselectedToken2] = useState("");
    const tokenOptions2 = [
        { value: "", label: "...select a token" },
        { value: "FOOKS", label: "FOOKS" },
        //{ value: "SGB", label: "SGB" },
        { value: "WSGB", label: "WSGB" },
        //{ value: "FLR", label: "FLR" },
        //{ value: "WFLR", label: "WFLR" },
    ];
    const handleTokenSelect2 = (e) => {
        setselectedToken2(e.value);
        localStorage.setItem('help-fook_token2', e.value);
    };
    const [selectedToken3, setselectedToken3] = useState("");
    const tokenOptions3 = [
        { value: "", label: "...select a token" },
        { value: "FOOKS", label: "FOOKS" },
        //{ value: "SGB", label: "SGB" },
        { value: "WSGB", label: "WSGB" },
        //{ value: "FLR", label: "FLR" },
        //{ value: "WFLR", label: "WFLR" },
    ];
    const handleTokenSelect3 = (e) => {
        setselectedToken3(e.value);
        localStorage.setItem('help-fook_token3', e.value);
    };
    const [selectedToken4, setselectedToken4] = useState("");
    const tokenOptions4 = [
        { value: "", label: "...select a token" },
        { value: "FOOK", label: "FOOK" },
        //{ value: "SGB", label: "SGB" },
        { value: "WSGB", label: "WSGB" },
        //{ value: "FLR", label: "FLR" },
        //{ value: "WFLR", label: "WFLR" },
    ];
    const handleTokenSelect4 = (e) => {
        setselectedToken4(e.value);
        localStorage.setItem('help-fook_token4', e.value);
    };

    //________________________________________________________________________________________
    //CLICK ACTIONS
    function StartFunction() {
        if (document.getElementById("start").innerHTML === "close") {

            document.getElementById("start").innerHTML = "start";
            document.getElementById("start").style.backgroundColor = "transparent";

            setshowMenu(false);
            setshowMenuOptions(false);
            setshowMenucomingSoon(false);
            setshowGenerator(false);

            //reset dropdown
            setSelectedOption("");
            //hide all form fields
            setshowGenemail(false); setshowGenname(false); setshowGentitle(false);
            setshowGendescription(false); setshowGenwebsite(false); setshowGencontract(false); setshowGenrecipient(false);
            setshowGentoken1(false); setshowGenamount1(false); setshowGentoken2(false); setshowGenamount2(false);
            setshowGentoken3(false); setshowGenamount3(false); setshowGentoken4(false); setshowGenamount4(false);
            setshowGentags(false); setshowGenimage(false); setshowGensoon(false); setshowGensubmit(false); setshowGenpayButtons(false);
            setshowPreviewButton(false);

            setshowFOOKSamt(true);
            setshowGensuccess(false);
            setTransactionHash('');
            setResult('');
            setError('');

        } else {
            document.getElementById("start").innerHTML = "close";
            document.getElementById("start").style.backgroundColor = "#e61f57";

            setshowMenu(true);
            setshowMenuOptions(true);

            setshowPreviewButton(false);
            setshowFOOKSamt(false);
            setshowGensuccess(false);
            setTransactionHash('');
            setResult('');
            setError('');
        }
    }
    function clickGenerator() {
        setshowMenu(false);
        setshowMenuOptions(false);
        setshowGenerator(true);
        setshowGenSelector(true);
        setshowGensubmit(false);
        setshowFOOKSamt(true);
        setshowGenpayButtons(false);
        setshowPreviewButton(false);
    }
    function clickPreview() {
        const button = document.getElementById('helpMode');
        button.click();
        setshowGensubmit(true);
    }
    function comingSoon() {
        setshowMenuOptions(false);
        setshowMenucomingSoon(true);
    }


    //________________________________________________________________________________________
    //FOOKS BALANCE ON HOMEPAGE
    const getFOOKSbalance = async () => {

        const chain = await provider.getNetwork();

        if (chain.chainId === 16) {
            setshowFOOKSamt(false);
        //_________
        } else if (chain.chainId === 19) {
            const currentAddress = await signer.getAddress();
            const FOOKSbalanceSGB = await useFooksTokenSGB.balanceOf(currentAddress);

            setBalance("you have " + new Intl.NumberFormat('en-US', options).format(FOOKSbalanceSGB / 10 ** 18) + " Songbird FOOKS");
        //_________
        } else if (chain.chainId === 14) {
            const currentAddress = await signer.getAddress();
            const FOOKSbalanceFLR = await useFooksTokenFLR.balanceOf(currentAddress);

            setBalance("you have " + new Intl.NumberFormat('en-US', options).format(FOOKSbalanceFLR / 10 ** 18) + " Flare FOOKS");
        //_________
        } else {
            setshowFOOKSamt(false);

        }
    };
    //end 'put balance on homepage' function


    //_________________________________________________________________________________
    //SUBMIT THE FORM DATA

    const handleSubmit = async () => {

        //if contract number is blank, error is thrown; set to null for cards that do not require it
        if (localStorage.getItem('help-fook_recipient') === 'address where the crypto is sent will display here') {
            localStorage.setItem('help-fook_recipient', "0xdB055877e6c13b6A6B25aBcAA29B393777dD0a73");
        }
        if (localStorage.getItem('help-fook_contract') === '') {
            localStorage.setItem('help-fook_contract', "0xdB055877e6c13b6A6B25aBcAA29B393777dD0a73");
        }
        if (localStorage.getItem('help-fook_name') === 'fook name') {
            localStorage.setItem('help-fook_name', '');
        }
        if (localStorage.getItem('help-fook_title') === 'title of your fook appears here \n[max 58 characters]') {
            localStorage.setItem('help-fook_title', '');
        }
        if (localStorage.getItem('help-fook_email') === '') {
            localStorage.setItem('help-fook_email', '');
        }
        if (localStorage.getItem('help-fook_amount1') === null) {
            localStorage.setItem('help-fook_amount1', '');
        }
        if (localStorage.getItem('help-fook_token1') === null) {
            localStorage.setItem('help-fook_token1', '');
        }
        if (localStorage.getItem('help-fook_amount2') === null) {
            localStorage.setItem('help-fook_amount2', '');
        }
        if (localStorage.getItem('help-fook_token2') === null) {
            localStorage.setItem('help-fook_token2', '');
        }
        if (localStorage.getItem('help-fook_amount3') === null) {
            localStorage.setItem('help-fook_amount3', '');
        }
        if (localStorage.getItem('help-fook_token3') === null) {
            localStorage.setItem('help-fook_token3', '');
        }
        if (localStorage.getItem('help-fook_amount4') === null) {
            localStorage.setItem('help-fook_amount4', '');
        }
        if (localStorage.getItem('help-fook_token4') === null) {
            localStorage.setItem('help-fook_token4', '');
        }
        if (localStorage.getItem('help-fook_imagelink') === null) {
            localStorage.setItem('help-fook_imagelink', '');
        }
        if (localStorage.getItem('help-fook_website') === 'website you provide will appear here \n[optional: max 1000 characters]') {
            localStorage.setItem('help-fook_website', '');
        }
        if (localStorage.getItem('help-help_fookcontentlink') === null) {
            localStorage.setItem('help-help_fookcontentlink', '');
        }
        if (localStorage.getItem('help-fook_description') === 'the description details you enter will display here \n[max 1000 characters]') {
            localStorage.setItem('help-fook_description', '');
        }
        if (localStorage.getItem('help-fook_tags') === null) {
            localStorage.setItem('help-fook_tags', '');
        }
        if (localStorage.getItem('help-fook_storage1') === null) {
            localStorage.setItem('help-fook_storage1', '');
        }
        if (localStorage.getItem('help-fook_storage2') === null) {
            localStorage.setItem('help-fook_storage2', '');
        }
        if (localStorage.getItem('help-fook_storage3') === null) {
            localStorage.setItem('help-fook_storage3', '');
        }

        localStorage.setItem('submit_fookTitles', localStorage.getItem('help-fook_name') + "|" + localStorage.getItem('help-fook_title') + "|" + localStorage.getItem('help-fook_email'));
        localStorage.setItem('submit_fookTokens', localStorage.getItem('help-fook_amount1') + "|" + localStorage.getItem('help-fook_token1') + "|" +
            localStorage.getItem('help-fook_amount2') + "|" + localStorage.getItem('help-fook_token2') + "|" +
            localStorage.getItem('help-fook_amount3') + "|" + localStorage.getItem('help-fook_token3') + "|" +
            localStorage.getItem('help-fook_amount4') + "|" + localStorage.getItem('help-fook_token4'));
        localStorage.setItem('submit_fookLinks', localStorage.getItem('help-fook_imagelink') + "|" + localStorage.getItem('help-fook_website') + "|" +
            localStorage.getItem('help_fookcontentlink'));



        if (window.ethereum.networkVersion === '16') {
            await provider.send('eth_requestAccounts', []); // <- this promps user to connect metamask
            setTransactionHash('');
            setshowApproveMessages('please approve the transaction...')
            setshowDYKmessage("did you know? \n\n\n this transaction only uses gas to send data to the contract.")
            showApprovalLayer();

            if (useFactoryCostonSignY) {
                const submitFormData = await useFactoryCostonSignY.setFook(
                    localStorage.getItem('help-fook_recipient'),
                    localStorage.getItem('help-fook_contract'),
                    localStorage.getItem('submit_fookTitles'),
                    localStorage.getItem('submit_fookTokens'),
                    localStorage.getItem('submit_fookLinks'),
                    localStorage.getItem('help-fook_description'),
                    localStorage.getItem('help-fook_tags'),
                    localStorage.getItem('help-fook_storage1'),
                    localStorage.getItem('help-fook_storage2'),
                    localStorage.getItem('help-fook_storage3'),
                    { gasLimit: 200000 }
                );
                if (submitFormData.hash === '') {
                    //the transaction was not successul
                    setTransactionHash('');
                    setshowApproveMessages('there seems to have been an error; the submission was not successful');

                } else {
                    setshowApproveButton(false);
                    //the transaction was successul
                    setTransactionHash('receipt: \n' + submitFormData.hash);
                    settransactionLink('https://coston-explorer.flare.network/tx/' + submitFormData.hash);
                    localStorage.setItem('receiptHash', transactionHash);
                    setresultMessage('[generating fook link]');
                    hideApprovalLayer();
                    showFookLinkSuccess();
                }
                await delay(3000);
                const lastfookNumber = await useFactoryCostonSignN.fookNumber();
                setresultMessage('here is your new fook link:')
                setResult('https://flarefooks.com/fook-link/' + showGentype + '/coston/' + lastfookNumber);
         
            }
        }
        if (window.ethereum.networkVersion === '19') {
            await provider.send('eth_requestAccounts', []); // <- this promps user to connect metamask
            setTransactionHash('');
            setshowApproveMessages('please approve the transaction...')
            setshowDYKmessage("did you know? \n\n\n this transaction only uses gas to send data to the contract.")
            showApprovalLayer();

            if (useFactorySGBSignY) {
                const submitFormData = await useFactorySGBSignY.setFook(
                    localStorage.getItem('help-fook_recipient'),
                    localStorage.getItem('help-fook_contract'),
                    localStorage.getItem('submit_fookTitles'),
                    localStorage.getItem('submit_fookTokens'),
                    localStorage.getItem('submit_fookLinks'),
                    localStorage.getItem('help-fook_description'),
                    localStorage.getItem('help-fook_tags'),
                    localStorage.getItem('help-fook_storage1'),
                    localStorage.getItem('help-fook_storage2'),
                    localStorage.getItem('help-fook_storage3'),
                    { gasLimit: 200000 }
                );
                if (submitFormData.hash === '') {
                    //the transaction was not successul
                    setTransactionHash('');
                    setshowApproveMessages('there seems to have been an error; the submission was not successful');
                } else {
                    setshowApproveButton(false);
                    //the transaction was successul
                    setTransactionHash('receipt: \n' + submitFormData.hash);
                    settransactionLink('https://songbird-explorer.flare.network/tx/' + submitFormData.hash);
                    localStorage.setItem('receiptHash', transactionHash);
                    setresultMessage('[generating fook link]');
                    hideApprovalLayer();
                    showFookLinkSuccess();
                }
                await delay(3000);
                const lastfookNumber = await useFactoryCostonSignN.fookNumber();
                setresultMessage('here is your new fook link:')
                setResult('https://flarefooks.com/fook-link/' + showGentype + '/songbird/' + lastfookNumber);
                
            }
        }

    };

    //____________________________________________
    //HTML data
    return (
        <div className="App">

            <div id="darkMode" className="btn-on" onClick={() => handleToggleDarkMode()}>dark</div>

            <div className='chainName'>{chainNameCard}</div>



            <div className="start-block">
                <div id="yourFOOKS" className={`slide-upMenu ${showFOOKSamt ? 'show' : 'hide'}`}>{balance}</div>
                <div id="menu" className={`slide-upMenu ${showMenu ? 'show' : 'hide'}`}>
                    <div id="menu-options" className={`slide-upMenu ${showMenuOptions ? 'show' : 'hide'}`}>
                        |&nbsp;&nbsp;<a id="menu-search" onClick={() => comingSoon()}>search</a>&nbsp;
                        |&nbsp;&nbsp;<a id="menu-generator" onClick={() => clickGenerator()}>generator</a>&nbsp;
                        |&nbsp;&nbsp;<a id="menu-factory" onClick={() => window.open('/fook-tokens', '_BLANK')}>buy fooks</a>&nbsp;&nbsp;|
                    </div>
                    <div id="menu-comingSoon" className={`slide-upStart ${showMenucomingSoon ? 'show' : 'hide'}`}>coming soon in a future update!</div>
                </div>

                <div className="font-title">flare &nbsp;
                    <div id="flare-f">f</div>ooks
                </div>

                <div className={`slide-upStart ${showWelcome ? 'show' : 'hide'}`}>
                    <div align="" id="welcomeMessage">developed with fookin' AI</div>
                </div>
                <div className={`slide-upStart ${showstartButton ? 'show' : 'hide'}`}>
                    <div id="start" style={{ zIndex: StartIndexState }} className={`slide-upStart ${showstartButton ? 'show' : 'hide'}`} onClick={() => StartFunction()}>start</div>
                </div>
            </div>

            <div id='form-success' className={`slide-upGenSuccess ${showGensuccess ? 'show' : 'hide'}`}>
                <div>{resultMessage}</div>
                <br />
                <a href={result} target="_blank" rel="noreferrer">{result}</a>
                <br />
                <br />
                <br />
                <div>
                </div>
                <a href={transactionLink} target="_blank" rel="noreferrer">{transactionHash}</a>
            </div>







            <div id="generator" className={`slide-upGenForm ${showGenerator ? 'show' : 'hide'}`}>
                <br /><br />
                <div>
                    <div className={`slide-upGenSelector ${showGenSelector ? 'show' : 'hide'}`}>
                        <Select id="gen-options" name="gen-selector" styles={{
                            option: provided => ({
                                ...provided,
                                left: '2%',
                                color: 'black'
                            }),
                            control: provided => ({
                                ...provided,
                                color: 'black',
                                left: '2%',
                                marginBottom: '10px',
                                width: '96%',
                            }),
                            singleValue: provided => ({
                                ...provided,
                                left: '2%',
                                color: 'black'
                            }),
                        }}
                            options={options}
                            onChange={handleTypeSelect}
                            value={options.find(function (option) {
                                return option.value === selectedOption;
                            })}
                        />
                    </div>
                </div>

                <form id="gen-form" className={`slide-upGenForm ${showGenerator ? 'show' : 'hide'}`}>


                    <div style={{ zIndex: AIndexState }} id="gen-payButtons" className={`slide-upGenPayButtons ${showGenpayButtons ? 'show' : 'hide'}`}>
                        <br />
                        <div>select a payment to start</div>
                        <br />

                        <p className='payButtons' onClick={() => switchandBuywithWSGB()}>WSGB</p>
                        &nbsp;&nbsp;&nbsp;
                        <p className='payButtons' onClick={() => switchandBuywithFOOKS()}>FOOKS</p>

                        <br /><br />
                        <a align='center' className={`slide-upGenSuccess ${showGensubmit ? 'show' : 'hide'}`} onClick={() => window.open('/fook-tokens', '_BLANK')}>pay with FOOKS and save 75%! click here to buy some</a>
                        <br /><br />
                    </div>






                    <div style={{ display: 'none' }} className={`slide-upGenForm ${showGentype ? 'show' : 'hide'}`}>
                        <input type="text" id="fooktype" name="fooktype" value={showGentype} /></div>

                    <div id="gen-email" className={`slide-upStart ${showGenemail ? 'show' : 'hide'}`}>your email address:<br />
                        <input type="text" name="gen-email" onChange={(e) => setInputValue(localStorage.setItem('help-fook_email', e.target.value))} /></div>

                    <div id="gen-name" className={`slide-upStart ${showGenname ? 'show' : 'hide'}`}>name your fook:<br />
                        <input type="text" placeholder="** required **" name="gen-name" onChange={(e) => setInputValue(localStorage.setItem('help-fook_name', e.target.value))} /></div>

                    <div id="gen-title" className={`slide-upStart ${showGentitle ? 'show' : 'hide'}`}>give your fook a 'title':<br />
                        <input type="text" placeholder="** required **" name="gen-title" onChange={(e) => setInputValue(localStorage.setItem('help-fook_title', e.target.value))} /></div>

                    <div id="gen-description" className={`slide-upStart ${showGendescription ? 'show' : 'hide'}`}>tell your story:<br />
                        <input type="text" placeholder="** 1000 characters max **" name="gen-description" onChange={(e) => setInputValue(localStorage.setItem('help-fook_description', e.target.value))} /></div>

                    <div id="gen-website" className={`slide-upStart ${showGenwebsite ? 'show' : 'hide'}`}>website to display:<br />
                        <input type="text" name="gen-website" onChange={(e) => setInputValue(localStorage.setItem('help-fook_website', e.target.value))} /></div>

                    <div id="gen-contract" className={`slide-upStart ${showGencontract ? 'show' : 'hide'}`}>contract number:<br />
                        <input type="text" placeholder="** required **" name="gen-contract" onChange={(e) => setInputValue(localStorage.setItem('help-fook_contract', e.target.value))} /></div>

                    <div id="gen-recipient" className={`slide-upStart ${showGenrecipient ? 'show' : 'hide'}`}>your wallet address<br />
                        <input type="text" placeholder="** required format 0x... **" name="gen-recipient" onChange={(e) => setInputValue(localStorage.setItem('help-fook_recipient', e.target.value))} /></div>

                    <div className={`slide-upStart ${showGentoken1 ? 'show' : 'hide'}`}>token [1st button]:<br />
                        <Select id="gen-token1" className="gen-select" name="gen-token1" styles={{
                            option: provided => ({
                                ...provided,
                                left: '2%',
                                color: 'black'
                            }),
                            control: provided => ({
                                ...provided,
                                color: 'black',
                                left: '2%',
                                marginTop: '10px',
                                marginBottom: '10px',
                                width: '96%',
                            }),
                            singleValue: provided => ({
                                ...provided,
                                left: '2%',
                                color: 'black'
                            }),
                        }}
                            menuPortalTarget={document.body}
                            options={tokenOptions1}
                            onChange={handleTokenSelect1}
                            value={tokenOptions1.find(function (option) {
                                return option.value === selectedToken1;
                            })}
                        />
                    </div>

                    <div id="gen-amount1" className={`slide-upStart ${showGenamount1 ? 'show' : 'hide'}`}>amount [1st button]:<br />
                        <input type="text" placeholder="** required **" name="gen-amount1" onChange={(e) => setInputValue(localStorage.setItem('help-fook_amount1', e.target.value))} /></div>

                    <div className={`slide-upStart ${showGentoken2 ? 'show' : 'hide'}`}>token [2nd button]:<br />
                        <Select id="gen-token2" name="gen-token2" className="gen-select" styles={{
                            option: provided => ({
                                ...provided,
                                left: '2%',
                                color: 'black'
                            }),
                            control: provided => ({
                                ...provided,
                                color: 'black',
                                left: '2%',
                                marginTop: '10px',
                                marginBottom: '10px',
                                width: '96%',
                            }),
                            singleValue: provided => ({
                                ...provided,
                                left: '2%',
                                color: 'black'
                            }),
                        }}
                            menuPortalTarget={document.body}
                            options={tokenOptions2}
                            onChange={handleTokenSelect2}
                            value={tokenOptions2.find(function (option) {
                                return option.value === selectedToken2;
                            })}


                        />
                    </div>

                    <div id="gen-amount2" className={`slide-upStart ${showGenamount2 ? 'show' : 'hide'}`}>amount [2nd button]:<br />
                        <input type="text" placeholder="** required **" name="gen-amount2" onChange={(e) => setInputValue(localStorage.setItem('help-fook_amount2', e.target.value))} /></div>

                    <div className={`slide-upStart ${showGentoken3 ? 'show' : 'hide'}`}>token [3rd button]:<br />
                        <Select id="gen-token3" name="gen-token3" className="gen-select" styles={{
                            option: provided => ({
                                ...provided,
                                left: '2%',
                                color: 'black'
                            }),
                            control: provided => ({
                                ...provided,
                                color: 'black',
                                left: '2%',
                                marginTop: '10px',
                                marginBottom: '10px',
                                width: '96%',
                            }),
                            singleValue: provided => ({
                                ...provided,
                                left: '2%',
                                color: 'black'
                            }),
                        }}
                            menuPortalTarget={document.body}
                            options={tokenOptions3}
                            onChange={handleTokenSelect3}
                            value={tokenOptions3.find(function (option) {
                                return option.value === selectedToken3;
                            })}
                        />
                    </div>

                    <div id="gen-amount3" className={`slide-upStart ${showGenamount3 ? 'show' : 'hide'}`}>amount [3rd button]:<br />
                        <input type="text" placeholder="** required **" name="gen-amount3" onChange={(e) => setInputValue(localStorage.setItem('help-fook_amount3', e.target.value))} /></div>

                    <div id="gen-tags" className={`slide-upStart ${showGentags ? 'show' : 'hide'}`}>keywords [for search]:<br />
                        <input type="text" placeholder="** required **" name="gen-tags" onChange={(e) => setInputValue(localStorage.setItem('help-fook_tags', e.target.value))} /></div>

                    <div id="gen-image" className={`slide-upStart ${showGenimage ? 'show' : 'hide'}`}>photo URL [link]:<br />
                        <input type="text" name="gen-image" onChange={(e) => setInputValue(localStorage.setItem('help-fook_imagelink', e.target.value))} /></div>

                    <div id="gen-comingSoon" className={`slide-submitButton ${showGensoon ? 'show' : 'hide'}`}>{selectedOption} is a planned feature!</div>
                    <br />
                    <div className={`slide-upMenu ${showPreviewButton ? 'show' : 'hide'}`}>
                        <div id="gen-fookVerify" onClick={() => clickPreview()}>preview</div>
                    </div>
                    <br />
                    <div className={`slide-submitButton ${showGensubmit ? 'show' : 'hide'}`}>
                        <div id="gen-fookVerify" onClick={() => handleSubmit()}>submit</div>
                    </div>
                </form>

            </div>



            <div>{error}</div>



            <div style={{ zIndex: zIndexState }} className={`back-Mainoverlay ${showApproveButton ? 'show' : 'hide'}`}>
                <div id="approval-objects" >
                    <div className="approveMessage">{showApproveMessages}</div>
                    <br />
                    <div id="goBackbutton" className="payButtons" onClick={() => hideApprovalLayer()}>GO BACK</div>
                    <div className="DYKmessage">{showDYKmessage}</div>
                </div>
            </div>


        </div>
    );
}

export default Fook;