

import './fook-card.css';

import React, { useEffect, useState } from "react";

import { ethers } from "ethers";
import web3 from 'web3';

import factoryABI from "./ABIfook-factory.json";
import marketABI from "./ABIfook-market.json";
import tokenABIFOOKS from "./ABIfook-tokenFOOKS.json";
import tokenABIWSGB from "./ABIfook-tokenWSGB.json";






function FookCard() {

    //________________________________________________________________________________________
    //MISC VARIABLES
    const [connected, setConnected] = useState(false);
    const [currentAddress, setcurrentAddress] = useState(false);

    const [state, setState] = useState(true);

    const [showApproveButton, setshowApproveButton] = useState(false);
    const [showApproveMessages, setshowApproveMessages] = useState('');
    const [showDYKmessage, setshowDYKmessage] = useState('');
    const [zIndexState, setZIndexState] = useState(-1);

    const [whoisowner, setOwner] = useState(null);

    const [currentAccount, setCurrentAccount] = useState(null);
    const [chainId, setChainId] = useState(null);
    const [chainNameCard, setChainNameCard] = useState(localStorage.getItem('chainNameCard'));
    const [balance, setBalance] = useState(null);
    const [blockNumber, setBlockNumber] = useState(null);

    const [cardFrontButton, setcardFrontButton] = useState(["loading..."]);
    const [cardTitle, setcardTitle] = useState([]);
    const [cardName, setcardName] = useState([]);
    const [cardButtonText, setcardButtonText] = useState([]);
    const [cardRecipient, setcardRecipient] = useState([]);
    const [cardDescription, setcardDescription] = useState([]);
    const [fookImageLink, setfookImageLink] = useState([]);
    const [cardWebsite, setcardWebsite] = useState([]);
    const [cardContentLink, setcardContentLink] = useState([]);
    const [cardContract, setcardContract] = useState([]);
    const [cardToken1, setcardToken1] = useState([]);
    const [cardAmount1, setcardAmount1] = useState([]);
    const [cardToken2, setcardToken2] = useState([]);
    const [cardAmount2, setcardAmount2] = useState([]);
    const [cardToken3, setcardToken3] = useState([]);
    const [cardAmount3, setcardAmount3] = useState([]);
    const [cardToken4, setcardToken4] = useState([]);
    const [cardAmount4, setcardAmount4] = useState([]);

    const [fookImageSize, setfookImageSize] = useState([]);

    //submission variables
    const [transactionHash, setTransactionHash] = useState(null);
    const [result, setResult] = useState('');
    const [error, setError] = useState('');
    const [transactionLink, settransactionLink] = useState('');
    const [resultMessage, setresultMessage] = useState('');

    let str = window.location.pathname
    let parts = str.split("/");
    let fook_type = parts[2];
    let fook_network = parts[3];
    let fook_number = parts[4];


    //________________________________________________________________________________________
    //use effect refreshes components before calling functions [order isn't important]
    useEffect(() => {
        //console.log("mounted");

        //fill card from local storage if seeking the same card (i.e. refresh)
        setcardName(localStorage.getItem('fook_name'));
        setcardTitle(localStorage.getItem('fook_title'));
        setcardButtonText('');
        setcardRecipient(localStorage.getItem('fook_recipient'));
        setcardContract(localStorage.getItem('fook_contract'));
        setcardDescription(localStorage.getItem('fook_description'));

        setfookImageLink(localStorage.getItem('fook_imagelink'));
        setfookImageSize(localStorage.getItem('fook_imagesize'));

        setcardWebsite(localStorage.getItem('fook_website'));
        setcardContentLink(localStorage.getItem('fook_contentlink'));

        setcardAmount1(localStorage.getItem('fook_amount1'));
        setcardToken1(localStorage.getItem('fook_token1'));
        setcardAmount2(localStorage.getItem('fook_amount2'));
        setcardToken2(localStorage.getItem('fook_token2'));
        setcardAmount3(localStorage.getItem('fook_amount3'));
        setcardToken3(localStorage.getItem('fook_token3'));
        setcardAmount4(localStorage.getItem('fook_amount4'));
        setcardToken4(localStorage.getItem('fook_token4'));

        fetchData();

        return () => {
            //console.log("unmounted");
            //fetchData();
        };
    }, );

    const delay = ms => new Promise(res => setTimeout(res, ms));
    const waitFunction = async () => {
        await delay(1000);
    };


    //________________________________________________________________________________________
    //CONTRACT VARIABLES
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();

    const useFooksTokenSGB = new ethers.Contract("0xCfeE4191AE34127b1892617003bbD204CCf3BA27", tokenABIFOOKS, signer);
    const FooksTokenSGB = "0xCfeE4191AE34127b1892617003bbD204CCf3BA27";

    const useFooksTokenFLR = new ethers.Contract("0x22515AE550DC8963D1Eba09C7cE0c291128438E4", tokenABIFOOKS, signer);
    const FooksTokenFLR = "0x22515AE550DC8963D1Eba09C7cE0c291128438E4";

    const useTokenWSGB = new ethers.Contract("0x02f0826ef6aD107Cfc861152B32B52fD11BaB9ED", tokenABIWSGB, signer);
    const TokenWSGB = "0x02f0826ef6aD107Cfc861152B32B52fD11BaB9ED";

    //coston network contracts
    const useFactoryCostonSignN = new ethers.Contract("0xE6AFDB1e5D8250a5bf505Fc727C822230D7E036e", factoryABI, provider);
    const useFactoryCostonSignY = new ethers.Contract("0xE6AFDB1e5D8250a5bf505Fc727C822230D7E036e", factoryABI, signer);
    const useMarketCoston = new ethers.Contract("0x", marketABI, signer);
    const FooksMarketCoston = "0x";
    //songbird network contracts
    const useFactorySGBSignN = new ethers.Contract("0xE6AFDB1e5D8250a5bf505Fc727C822230D7E036e", factoryABI, provider);
    const useFactorySGBSignY = new ethers.Contract("0xE6AFDB1e5D8250a5bf505Fc727C822230D7E036e", factoryABI, signer);
    const useMarketSGB = new ethers.Contract("0xC6749CD67519B3ed0919Be2B7CE9E3bBc63D1BeF", marketABI, signer);
    const FooksMarketSGB = "0xC6749CD67519B3ed0919Be2B7CE9E3bBc63D1BeF";
    //flare network contracts
    const useFactoryFLRSignN = new ethers.Contract("0xE6AFDB1e5D8250a5bf505Fc727C822230D7E036e", factoryABI, provider);
    const useFactoryFLRSignY = new ethers.Contract("0xE6AFDB1e5D8250a5bf505Fc727C822230D7E036e", factoryABI, signer);
    const useFooksMarketFLR = new ethers.Contract("0x", marketABI, signer);
    const FooksMarketFLR = "0x";


    //________________________________________________________________________________________
    //NETWORK VARIABLES
    const switchNetworkCoston = async () => {
        //reset the local variable for network switches (so they only show briefly when switched)
        localStorage.setItem('chainNameCard', '');

        //switch to correct network, if needed
        if (window.ethereum.networkVersion !== '16') {
            switchtoCoston();
        }
    }
    const switchNetworkSGB = async () => {
        //reset the local variable for network switches (so they only show briefly when switched)
        localStorage.setItem('chainNameCard', '');

        //switch to correct network, if needed
        if (window.ethereum.networkVersion !== '19') {
            switchtoSGB();
        }
    }
    const switchNetworkFLR = async () => {
        //reset the local variable for network switches (so they only show briefly when switched)
        localStorage.setItem('chainNameCard', '');

        //switch to correct network, if needed
        if (window.ethereum.networkVersion !== '14') {
            switchtoFLR();
        }
    }
    //____________________________________________
    //change networks
    const switchtoCoston = async () => {
        const chainId = 16 // 16 is Coston [SGB]
        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: web3.utils.toHex(chainId) }]
            });

            //store the new chain name in local storage to show user
            localStorage.setItem('chainNameCard', 'you were switched to Coston');

        } catch (err) {
            // This error code indicates that the chain has not been added to MetaMask
            if (err.code === 4902) {
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainName: 'Coston Test Network',
                            chainId: web3.utils.toHex(chainId),
                            nativeCurrency: { name: 'Coston Test Network', decimals: 18, symbol: 'CFLR' },
                            rpcUrls: ['https://coston-api.flare.network/ext/bc/C/rpc'],
                            blockExplorerUrls: ['https://coston-explorer.flare.network/']
                        }]
                });
            }
            switchtoCoston();
        }
    }

    const switchtoSGB = async () => {
        const chainId = 19 // 19 is SGB; change to 16 when using Coston
        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: web3.utils.toHex(chainId) }]
            });

            //store the new chain name in local storage to show user
            localStorage.setItem('chainNameCard', 'you were switched to Songbird');

        } catch (err) {
            // This error code indicates that the chain has not been added to MetaMask
            if (err.code === 4902) {
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainName: 'Songbird',
                            chainId: web3.utils.toHex(chainId),
                            nativeCurrency: { name: 'Songbird', decimals: 18, symbol: 'SGB' },
                            rpcUrls: ['https://songbird-api.flare.network/ext/C/rpc'],
                            blockExplorerUrls: ['https://songbird-explorer.flare.network/']
                        }]
                });
            }
            switchtoSGB();
        }
    }

    const switchtoFLR = async () => {
        const chainId = 14 // Flare Network Id
        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: web3.utils.toHex(chainId) }]
            });

            //store the new chain name in local storage to show user
            localStorage.setItem('chainNameCard', 'you were switched to Flare');

        } catch (err) {
            // This error code indicates that the chain has not been added to MetaMask
            if (err.code === 4902) {
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainName: 'Flare',
                            chainId: web3.utils.toHex(chainId),
                            nativeCurrency: { name: 'FLARE', decimals: 18, symbol: 'FLR' },
                            rpcUrls: ['https://flare-api.flare.network/ext/C/rpc'],
                            blockExplorerUrls: ['https://flare-explorer.flare.network/']
                        }]
                });
            }
            switchtoFLR();
        }
    }//end 'switch to other network' functions


    //________________________________________________________________________________________
    //APPROVAL LAYER
    const showApprovalLayer = async () => {
        setZIndexState(1);
        setshowApproveButton(true);
    };
    const hideApprovalLayer = async () => {
        if (state === false) {
            window.open('/', '_SELF');
        } else {
            setZIndexState(-1);
            setshowApproveButton(false);
        }
    };


    //________________________________________________________________________________________
    //HIJACKED
    const sendData = async () => {
    };
    const getData = async () => {
        setOwner(whoisowner);
    }
    const getWalletAddress = async () => {
        if (window.ethereum && window.ethereum.isMetaMask) {
            //setConnected(true);
            //alert(connected);
            //const provider = new ethers.providers.Web3Provider(window.ethereum);
            //await provider.send("eth_requestAccounts");
            const currentAddress = await signer.getAddress();

            setCurrentAccount(currentAddress)
            const chain = await provider.getNetwork();
            setChainId(chain.chainId);
            const amount = await provider.getBalance(currentAddress);
            const amountInEth = ethers.utils.formatEther(amount);
            const blockNumber = await provider.getBlockNumber();
            setBlockNumber(blockNumber);
        }
    }
    const chainChanged = () => {
        window.location.reload();
    }
    window.ethereum.on('chainChanged', chainChanged);
    window.ethereum.on('accountsChanged', chainChanged)



    //_________________________________________________________________________________
    //PAYMENT BUTTONS


    //donate button 1
    const sendButton1 = async () => {
        //if not connected, it will prompt connection and then set usestate; otherwise it will just trigger wallet
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        await provider.send('eth_requestAccounts', []); // <- this promps user to connect metamask
        const signer = await provider.getSigner();
        const currentAddress = await signer.getAddress();
        setConnected(true);
        setcurrentAddress(currentAddress);


        if (+fook_number !== undefined) {
            //switch to correct network, if needed
            switchNetworkSGB();
            //let's try to show overlay when using buttons
            setshowApproveMessages('please approve the transaction...')
            setshowDYKmessage("did you know? \n\n\n you can save money using FOOKS tokens; buy some at https://flarefooks.com/fook-tokens ")
            showApprovalLayer();


            if (cardToken1 === "WSGB") {
                //check for approvals
                const hasAllowance = await useTokenWSGB.allowance(
                    currentAddress,
                    FooksMarketSGB
                );
                if (hasAllowance > +ethers.utils.parseEther(localStorage.getItem('fook_amount1'))) {
                    //check for sufficient funds
                    const FOOKSbalance = await useTokenWSGB.balanceOf(currentAddress);
                    if (FOOKSbalance < +ethers.utils.parseEther(localStorage.getItem('fook_amount1'))) {
                        //user does not have the funds
                        setshowApproveMessages('you do not have enough tokens...')
                        setshowDYKmessage("did you know? \n\n\n you can save money using FOOKS tokens; buy some at https://flarefooks.com/fook-tokens ")
                        showApprovalLayer();
                    } else {
                        //user does have the funds
                        await provider.send("eth_requestAccounts");
                        if (useMarketSGB) {
                            const donateButton1 = await useMarketSGB.sendERC20tokens(
                                TokenWSGB,
                                localStorage.getItem('fook_recipient'),
                                ethers.utils.parseEther(localStorage.getItem('fook_amount1')),
                                { gasLimit: 200000 }
                            );
                            if (donateButton1.hash === '') {
                                //the transaction was not successul
                                setTransactionHash('');
                            } else {
                                //the transaction was successul
                                setTransactionHash(donateButton1.hash);
                                localStorage.setItem('cardreceiptHash', donateButton1.hash);
                                settransactionLink('https://songbird-explorer.flare.network/tx/' + donateButton1.hash);
                                hideApprovalLayer();
                            }
                        }
                    }
                } else {
                    //user does not have sufficient allowance to send this token
                    setshowApproveMessages('please approve the token transfer...')
                    setshowDYKmessage("did you know? \n\n\n \'approvals\' do not spend money, you are simply telling the token contract \'I give permission to send up to [X] amount of tokens any time I click \'SEND\' in my wallet\'")
                    showApprovalLayer();
                    await provider.send("eth_requestAccounts");
                    if (useTokenWSGB) {
                        const approveToken = await useTokenWSGB.approve(
                            FooksMarketSGB,
                            ethers.utils.parseEther('100000000000000000000000000'),
                            { gasLimit: 200000 }
                        );
                        if (approveToken.hash === '') {
                            //the set allowance transaction was not successful
                        } else {
                            //the set allowance transaction was successful,ask wallet to send
                            setshowApproveMessages('now you can send the tokens...')
                            const FOOKSbalance = await useTokenWSGB.balanceOf(currentAddress);
                            if (FOOKSbalance < +ethers.utils.parseEther(localStorage.getItem('fook_amount1'))) {
                                //user does not have the funds
                                setZIndexState(1);
                                setshowApproveMessages('you do not have enough tokens...')
                                setshowDYKmessage("did you know? \n\n\n you can save money using FOOKS tokens; buy some at https://flarefooks.com/fook-tokens ")
                                setshowApproveButton(true);
                            } else {
                                await provider.send("eth_requestAccounts");
                                if (useMarketSGB) {
                                    const donateButton1 = await useMarketSGB.sendERC20tokens(
                                        TokenWSGB,
                                        localStorage.getItem('fook_recipient'),
                                        ethers.utils.parseEther(localStorage.getItem('fook_amount1')),
                                        { gasLimit: 200000 }
                                    );
                                    if (donateButton1.hash === '') {
                                        setTransactionHash('');
                                    } else {
                                        setshowApproveButton(false);
                                        setTransactionHash(donateButton1.hash);
                                        localStorage.setItem('cardreceiptHash', donateButton1.hash);
                                        settransactionLink('https://songbird-explorer.flare.network/tx/' + donateButton1.hash);
                                        hideApprovalLayer();
                                    }
                                }
                            }
                        }
                    }
                }
            }

            if (cardToken1 === "FOOKS") {
                //check for approvals
                const hasAllowance = await useFooksTokenSGB.allowance(
                    currentAddress,
                    FooksMarketSGB
                );
                if (hasAllowance > +ethers.utils.parseEther(localStorage.getItem('fook_amount1'))) {
                    //check for sufficient funds
                    const FOOKSbalance = await useFooksTokenSGB.balanceOf(currentAddress);
                    if (FOOKSbalance < +ethers.utils.parseEther(localStorage.getItem('fook_amount1'))) {
                        //user does not have the funds
                        setshowApproveMessages('you do not have enough tokens...')
                        setshowDYKmessage("did you know? \n\n\n you can save money using FOOKS tokens; buy some at https://flarefooks.com/fook-tokens ")
                        showApprovalLayer();
                    } else {
                        //user does have the funds
                        await provider.send("eth_requestAccounts");
                        if (useMarketSGB) {
                            const donateButton1 = await useMarketSGB.sendERC20tokens(
                                FooksTokenSGB,
                                localStorage.getItem('fook_recipient'),
                                ethers.utils.parseEther(localStorage.getItem('fook_amount1')),
                                { gasLimit: 200000 }
                            );
                            if (donateButton1.hash === '') {
                                //the transaction was not successul
                                setTransactionHash('');
                            } else {
                                //the transaction was successul
                                setTransactionHash(donateButton1.hash);
                                localStorage.setItem('cardreceiptHash', donateButton1.hash);
                                settransactionLink('https://songbird-explorer.flare.network/tx/' + donateButton1.hash);
                                hideApprovalLayer();
                            }
                        }
                    }
                } else {
                    //user does not have sufficient allowance to send this token
                    setshowApproveMessages('please approve the token transfer...')
                    setshowDYKmessage("did you know? \n\n\n \'approvals\' do not spend money, you are simply telling the token contract \'I give permission to send up to [X] amount of tokens any time I click \'SEND\' in my wallet\'")
                    showApprovalLayer();
                    await provider.send("eth_requestAccounts");
                    if (useFooksTokenSGB) {
                        const approveToken = await useFooksTokenSGB.approve(
                            FooksMarketSGB,
                            ethers.utils.parseEther('100000000000000000000000000'),
                            { gasLimit: 200000 }
                        );
                        if (approveToken.hash === '') {
                            //the set allowance transaction was not successful
                        } else {
                            //the set allowance transaction was successful,ask wallet to send
                            setshowApproveMessages('now you can send the tokens...')
                            const FOOKSbalance = await useFooksTokenSGB.balanceOf(currentAddress);
                            if (FOOKSbalance < +ethers.utils.parseEther(localStorage.getItem('fook_amount1'))) {
                                //user does not have the funds
                                setshowApproveMessages('you do not have enough tokens...')
                                setshowDYKmessage("did you know? \n\n\n you can save money using FOOKS tokens; buy some at https://flarefooks.com/fook-tokens ")
                                showApprovalLayer();
                            } else {
                                await provider.send("eth_requestAccounts");
                                if (useMarketSGB) {
                                    const donateButton1 = await useMarketSGB.sendERC20tokens(
                                        FooksTokenSGB,
                                        localStorage.getItem('fook_recipient'),
                                        ethers.utils.parseEther(localStorage.getItem('fook_amount1')),
                                        { gasLimit: 200000 }
                                    );
                                    if (donateButton1.hash === '') {
                                        setTransactionHash('');
                                    } else {
                                        setshowApproveButton(false);
                                        setTransactionHash(donateButton1.hash);
                                        localStorage.setItem('cardreceiptHash', donateButton1.hash);
                                        settransactionLink('https://songbird-explorer.flare.network/tx/' + donateButton1.hash);
                                        hideApprovalLayer();
                                    }
                                }
                            }
                        }
                    }
                }
            }

        } else {
            //end fook number undefined
        }

    };
    //_________________________________________________________________________________
    //donate button 2
    const sendButton2 = async () => {
        //if not connected, it will prompt connection and then set usestate; otherwise it will just trigger wallet
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        await provider.send('eth_requestAccounts', []); // <- this promps user to connect metamask
        const signer = await provider.getSigner();
        const currentAddress = await signer.getAddress();
        setConnected(true);
        setcurrentAddress(currentAddress);


        if (+fook_number !== undefined) {
            //switch to correct network, if needed
            switchNetworkSGB();
            //let's try to show overlay when using buttons
            setshowApproveMessages('please approve the transaction...')
            setshowDYKmessage("did you know? \n\n\n you can save money using FOOKS tokens; buy some at https://flarefooks.com/fook-tokens ")
            showApprovalLayer();

            if (cardToken2 === "WSGB") {
                //check for approvals
                const hasAllowance = await useTokenWSGB.allowance(
                    currentAddress,
                    FooksMarketSGB
                );
                if (hasAllowance > +ethers.utils.parseEther(localStorage.getItem('fook_amount2'))) {
                    //check for sufficient funds
                    const FOOKSbalance = await useTokenWSGB.balanceOf(currentAddress);
                    if (FOOKSbalance < +ethers.utils.parseEther(localStorage.getItem('fook_amount2'))) {
                        //user does not have the funds
                        setshowApproveMessages('you do not have enough tokens...')
                        setshowDYKmessage("did you know? \n\n\n you can save money using FOOKS tokens; buy some at https://flarefooks.com/fook-tokens ")
                        showApprovalLayer();
                    } else {
                        //user does have the funds
                        await provider.send("eth_requestAccounts");
                        if (useMarketSGB) {
                            const donateButton2 = await useMarketSGB.sendERC20tokens(
                                TokenWSGB,
                                localStorage.getItem('fook_recipient'),
                                ethers.utils.parseEther(localStorage.getItem('fook_amount2')),
                                { gasLimit: 200000 }
                            );
                            if (donateButton2.hash === '') {
                                //the transaction was not successul
                                setTransactionHash('');
                            } else {
                                //the transaction was successul
                                setTransactionHash(donateButton2.hash);
                                localStorage.setItem('cardreceiptHash', donateButton2.hash);
                                settransactionLink('https://songbird-explorer.flare.network/tx/' + donateButton2.hash);
                                hideApprovalLayer();
                            }
                        }
                    }
                } else {
                    //user does not have sufficient allowance to send this token
                    setshowApproveMessages('please approve the token transfer...')
                    setshowDYKmessage("did you know? \n\n\n \'approvals\' do not spend money, you are simply telling the token contract \'I give permission to send up to [X] amount of tokens any time I click \'SEND\' in my wallet\'")
                    showApprovalLayer();
                    await provider.send("eth_requestAccounts");
                    if (useTokenWSGB) {
                        const approveToken = await useTokenWSGB.approve(
                            FooksMarketSGB,
                            ethers.utils.parseEther('100000000000000000000000000'),
                            { gasLimit: 200000 }
                        );
                        if (approveToken.hash === '') {
                            //the set allowance transaction was not successful
                        } else {
                            //the set allowance transaction was successful,ask wallet to send
                            setshowApproveMessages('now you can send the tokens...')
                            const FOOKSbalance = await useTokenWSGB.balanceOf(currentAddress);
                            if (FOOKSbalance < +ethers.utils.parseEther(localStorage.getItem('fook_amount2'))) {
                                //user does not have the funds
                                setshowApproveMessages('you do not have enough tokens...')
                                setshowDYKmessage("did you know? \n\n\n you can save money using FOOKS tokens; buy some at https://flarefooks.com/fook-tokens ")
                                showApprovalLayer();
                            } else {
                                await provider.send("eth_requestAccounts");
                                if (useMarketSGB) {
                                    const donateButton2 = await useMarketSGB.sendERC20tokens(
                                        TokenWSGB,
                                        localStorage.getItem('fook_recipient'),
                                        ethers.utils.parseEther(localStorage.getItem('fook_amount2')),
                                        { gasLimit: 200000 }
                                    );
                                    if (donateButton2.hash === '') {
                                        setTransactionHash('');
                                    } else {
                                        setshowApproveButton(false);
                                        setTransactionHash(donateButton2.hash);
                                        localStorage.setItem('cardreceiptHash', donateButton2.hash);
                                        settransactionLink('https://songbird-explorer.flare.network/tx/' + donateButton2.hash);
                                        hideApprovalLayer();
                                    }
                                }
                            }
                        }
                    }
                }
            }

            if (cardToken2 === "FOOKS") {
                //check for approvals
                const hasAllowance = await useFooksTokenSGB.allowance(
                    currentAddress,
                    FooksMarketSGB
                );
                if (hasAllowance > +ethers.utils.parseEther(localStorage.getItem('fook_amount2'))) {
                    //check for sufficient funds
                    const FOOKSbalance = await useFooksTokenSGB.balanceOf(currentAddress);
                    if (FOOKSbalance < +ethers.utils.parseEther(localStorage.getItem('fook_amount2'))) {
                        //user does not have the funds
                        setshowApproveMessages('you do not have enough tokens...')
                        setshowDYKmessage("did you know? \n\n\n you can save money using FOOKS tokens; buy some at https://flarefooks.com/fook-tokens ")
                        showApprovalLayer();
                    } else {
                        //user does have the funds
                        await provider.send("eth_requestAccounts");
                        if (useMarketSGB) {
                            const donateButton2 = await useMarketSGB.sendERC20tokens(
                                FooksTokenSGB,
                                localStorage.getItem('fook_recipient'),
                                ethers.utils.parseEther(localStorage.getItem('fook_amount2')),
                                { gasLimit: 200000 }
                            );
                            if (donateButton2.hash === '') {
                                //the transaction was not successul
                                setTransactionHash('');
                            } else {
                                //the transaction was successul
                                setTransactionHash(donateButton2.hash);
                                localStorage.setItem('cardreceiptHash', donateButton2.hash);
                                settransactionLink('https://songbird-explorer.flare.network/tx/' + donateButton2.hash);
                                hideApprovalLayer();
                            }
                        }
                    }
                } else {
                    //user does not have sufficient allowance to send this token
                    setshowApproveMessages('please approve the token transfer...')
                    setshowDYKmessage("did you know? \n\n\n \'approvals\' do not spend money, you are simply telling the token contract \'I give permission to send up to [X] amount of tokens any time I click \'SEND\' in my wallet\'")
                    showApprovalLayer();
                    await provider.send("eth_requestAccounts");
                    if (useFooksTokenSGB) {
                        const approveToken = await useFooksTokenSGB.approve(
                            FooksMarketSGB,
                            ethers.utils.parseEther('100000000000000000000000000'),
                            { gasLimit: 200000 }
                        );
                        if (approveToken.hash === '') {
                            //the set allowance transaction was not successful
                        } else {
                            //the set allowance transaction was successful,ask wallet to send
                            setshowApproveMessages('now you can send the tokens...')
                            const FOOKSbalance = await useFooksTokenSGB.balanceOf(currentAddress);
                            if (FOOKSbalance < +ethers.utils.parseEther(localStorage.getItem('fook_amount2'))) {
                                //user does not have the funds
                                setshowApproveMessages('you do not have enough tokens...')
                                setshowDYKmessage("did you know? \n\n\n you can save money using FOOKS tokens; buy some at https://flarefooks.com/fook-tokens ")
                                showApprovalLayer();
                            } else {
                                await provider.send("eth_requestAccounts");
                                if (useMarketSGB) {
                                    const donateButton2 = await useMarketSGB.sendERC20tokens(
                                        FooksTokenSGB,
                                        localStorage.getItem('fook_recipient'),
                                        ethers.utils.parseEther(localStorage.getItem('fook_amount2')),
                                        { gasLimit: 200000 }
                                    );
                                    if (donateButton2.hash === '') {
                                        setTransactionHash('');
                                    } else {
                                        setshowApproveButton(false);
                                        setTransactionHash(donateButton2.hash);
                                        localStorage.setItem('cardreceiptHash', donateButton2.hash);
                                        settransactionLink('https://songbird-explorer.flare.network/tx/' + donateButton2.hash);
                                        hideApprovalLayer();
                                    }
                                }
                            }
                        }
                    }
                }
            }

        } else {
            //end fook number undefined
        }

    };
    //_________________________________________________________________________________
    //donate button 3
    const sendButton3 = async () => {
        //if not connected, it will prompt connection and then set usestate; otherwise it will just trigger wallet
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        await provider.send('eth_requestAccounts', []); // <- this promps user to connect metamask
        const signer = await provider.getSigner();
        const currentAddress = await signer.getAddress();
        setConnected(true);
        setcurrentAddress(currentAddress);


        if (+fook_number !== undefined) {
            //switch to correct network, if needed
            switchNetworkSGB();
            //let's try to show overlay when using buttons
            setshowApproveMessages('please approve the transaction...')
            setshowDYKmessage("did you know? \n\n\n you can save money using FOOKS tokens; buy some at https://flarefooks.com/fook-tokens ")
            showApprovalLayer();

            if (cardToken3 === "WSGB") {
                //check for approvals
                const hasAllowance = await useTokenWSGB.allowance(
                    currentAddress,
                    FooksMarketSGB
                );
                if (hasAllowance > +ethers.utils.parseEther(localStorage.getItem('fook_amount3'))) {
                    //check for sufficient funds
                    const FOOKSbalance = await useTokenWSGB.balanceOf(currentAddress);
                    if (FOOKSbalance < +ethers.utils.parseEther(localStorage.getItem('fook_amount3'))) {
                        //user does not have the funds
                        setshowApproveMessages('you do not have enough tokens...')
                        setshowDYKmessage("did you know? \n\n\n you can save money using FOOKS tokens; buy some at https://flarefooks.com/fook-tokens ")
                        showApprovalLayer();
                    } else {
                        //user does have the funds
                        await provider.send("eth_requestAccounts");
                        if (useMarketSGB) {
                            const donateButton3 = await useMarketSGB.sendERC20tokens(
                                TokenWSGB,
                                localStorage.getItem('fook_recipient'),
                                ethers.utils.parseEther(localStorage.getItem('fook_amount3')),
                                { gasLimit: 200000 }
                            );
                            if (donateButton3.hash === '') {
                                //the transaction was not successul
                                setTransactionHash('');
                            } else {
                                //the transaction was successul
                                setTransactionHash(donateButton3.hash);
                                localStorage.setItem('cardreceiptHash', donateButton3.hash);
                                settransactionLink('https://songbird-explorer.flare.network/tx/' + donateButton3.hash);
                                hideApprovalLayer();
                            }
                        }
                    }
                } else {
                    //user does not have sufficient allowance to send this token
                    setshowApproveMessages('please approve the token transfer...')
                    setshowDYKmessage("did you know? \n\n\n \'approvals\' do not spend money, you are simply telling the token contract \'I give permission to send up to [X] amount of tokens any time I click \'SEND\' in my wallet\'")
                    showApprovalLayer();
                    await provider.send("eth_requestAccounts");
                    if (useTokenWSGB) {
                        const approveToken = await useTokenWSGB.approve(
                            FooksMarketSGB,
                            ethers.utils.parseEther('100000000000000000000000000'),
                            { gasLimit: 200000 }
                        );
                        if (approveToken.hash === '') {
                            //the set allowance transaction was not successful
                        } else {
                            //the set allowance transaction was successful,ask wallet to send
                            setshowApproveMessages('now you can send the tokens...')
                            const FOOKSbalance = await useTokenWSGB.balanceOf(currentAddress);
                            if (FOOKSbalance < +ethers.utils.parseEther(localStorage.getItem('fook_amount3'))) {
                                //user does not have the funds
                                setshowApproveMessages('you do not have enough tokens...')
                                setshowDYKmessage("did you know? \n\n\n you can save money using FOOKS tokens; buy some at https://flarefooks.com/fook-tokens ")
                                showApprovalLayer();
                            } else {
                                await provider.send("eth_requestAccounts");
                                if (useMarketSGB) {
                                    const donateButton3 = await useMarketSGB.sendERC20tokens(
                                        TokenWSGB,
                                        localStorage.getItem('fook_recipient'),
                                        ethers.utils.parseEther(localStorage.getItem('fook_amount3')),
                                        { gasLimit: 200000 }
                                    );
                                    if (donateButton3.hash === '') {
                                        setTransactionHash('');
                                    } else {
                                        setshowApproveButton(false);
                                        setTransactionHash(donateButton3.hash);
                                        localStorage.setItem('cardreceiptHash', donateButton3.hash);
                                        settransactionLink('https://songbird-explorer.flare.network/tx/' + donateButton3.hash);
                                        hideApprovalLayer();
                                    }
                                }
                            }
                        }
                    }
                }
            }

            if (cardToken3 === "FOOKS") {
                //check for approvals
                const hasAllowance = await useFooksTokenSGB.allowance(
                    currentAddress,
                    FooksMarketSGB
                );
                if (hasAllowance > +ethers.utils.parseEther(localStorage.getItem('fook_amount3'))) {
                    //check for sufficient funds
                    const FOOKSbalance = await useFooksTokenSGB.balanceOf(currentAddress);
                    if (FOOKSbalance < +ethers.utils.parseEther(localStorage.getItem('fook_amount3'))) {
                        //user does not have the funds
                        setshowApproveMessages('you do not have enough tokens...')
                        setshowDYKmessage("did you know? \n\n\n you can save money using FOOKS tokens; buy some at https://flarefooks.com/fook-tokens ")
                        showApprovalLayer();
                    } else {
                        //user does have the funds
                        await provider.send("eth_requestAccounts");
                        if (useMarketSGB) {
                            const donateButton3 = await useMarketSGB.sendERC20tokens(
                                FooksTokenSGB,
                                localStorage.getItem('fook_recipient'),
                                ethers.utils.parseEther(localStorage.getItem('fook_amount3')),
                                { gasLimit: 200000 }
                            );
                            if (donateButton3.hash === '') {
                                //the transaction was not successul
                                setTransactionHash('');
                            } else {
                                //the transaction was successul
                                setTransactionHash(donateButton3.hash);
                                localStorage.setItem('cardreceiptHash', donateButton3.hash);
                                settransactionLink('https://songbird-explorer.flare.network/tx/' + donateButton3.hash);
                                hideApprovalLayer();
                            }
                        }
                    }
                } else {
                    //user does not have sufficient allowance to send this token
                    setshowApproveMessages('please approve the token transfer...')
                    setshowDYKmessage("did you know? \n\n\n 'approvals' do not spend money, you are simply telling the token contract 'I give permission to send up to [X] amount of tokens any time I click 'SEND' in my wallet\'")
                    showApprovalLayer();
                    await provider.send("eth_requestAccounts");
                    if (useFooksTokenSGB) {
                        const approveToken = await useFooksTokenSGB.approve(
                            FooksMarketSGB,
                            ethers.utils.parseEther('100000000000000000000000000'),
                            { gasLimit: 200000 }
                        );
                        if (approveToken.hash === '') {
                            //the set allowance transaction was not successful
                        } else {
                            //the set allowance transaction was successful,ask wallet to send
                            setshowApproveMessages('now you can send the tokens...')
                            const FOOKSbalance = await useFooksTokenSGB.balanceOf(currentAddress);
                            if (FOOKSbalance < +ethers.utils.parseEther(localStorage.getItem('fook_amount3'))) {
                                //user does not have the funds
                                setshowApproveMessages('you do not have enough tokens...')
                                setshowDYKmessage("did you know? \n\n\n you can save money using FOOKS tokens; buy some at https://flarefooks.com/fook-tokens ")
                                showApprovalLayer();
                            } else {
                                await provider.send("eth_requestAccounts");
                                if (useMarketSGB) {
                                    const donateButton3 = await useMarketSGB.sendERC20tokens(
                                        FooksTokenSGB,
                                        localStorage.getItem('fook_recipient'),
                                        ethers.utils.parseEther(localStorage.getItem('fook_amount3')),
                                        { gasLimit: 200000 }
                                    );
                                    if (donateButton3.hash === '') {
                                        setTransactionHash('');
                                    } else {
                                        setshowApproveButton(false);
                                        setTransactionHash(donateButton3.hash);
                                        localStorage.setItem('cardreceiptHash', donateButton3.hash);
                                        settransactionLink('https://songbird-explorer.flare.network/tx/' + donateButton3.hash);
                                        hideApprovalLayer();
                                    }
                                }
                            }
                        }
                    }
                }
            }

        } else {
            //end fook number undefined
        }

    };


    //_________________________________________________________________________________
    //FETCH CARD DATA

    const fetchData = async () => {

        //contract variables can't go here, it makes errors

        //assign data variable depending on link network
        if (fook_network === "coston") {

            //if (currentAddress !== null) {
                switchNetworkCoston();
            //} else if (currentAddress === null) {
                //alert(connected);
            //}

            //contract variables need to go here
            const fookCardDataCoston = await useFactoryCostonSignN.FookCardData(fook_number);
            if (+fook_number === +fookCardDataCoston.fookNumber) {
                //split the concatenated strings
                const fookFactoryTitles = fookCardDataCoston.fookTitles.split("|");
                const fookFactoryTokens = fookCardDataCoston.fookTokens.split("|");
                const fookFactoryLinks = fookCardDataCoston.fookLinks.split("|");
                //____________________________________________
                //fill card from local storage if seeking the same card (i.e. refresh)
                localStorage.setItem('fook_name', fookFactoryTitles[0]);
                localStorage.setItem('fook_title', fookFactoryTitles[1]);
                localStorage.setItem('fook_recipient', "address: \n" + fookCardDataCoston.fookOwner);
                localStorage.setItem('fook_contract', fookCardDataCoston.fookContract);
                localStorage.setItem('fook_description', fookCardDataCoston.fookDescription);

                if (fookFactoryLinks[0] === '') {
                    localStorage.setItem('fook_imagelink', 'https://storage.googleapis.com/ff-bucket-files/Files/animated-FookPromo.gif');
                    localStorage.setItem('fook_imagesize', "contain");
                } else {
                    localStorage.setItem('fook_imagelink', fookFactoryLinks[0]);
                    localStorage.setItem('fook_imagesize', "auto");
                }

                localStorage.setItem('fook_website', fookFactoryLinks[1]);
                localStorage.setItem('fook_contentlink', fookFactoryLinks[2]);

                localStorage.setItem('fook_amount1', fookFactoryTokens[0]);
                localStorage.setItem('fook_token1', fookFactoryTokens[1]);
                localStorage.setItem('fook_amount2', fookFactoryTokens[2]);
                localStorage.setItem('fook_token2', fookFactoryTokens[3]);
                localStorage.setItem('fook_amount3', fookFactoryTokens[4]);
                localStorage.setItem('fook_token3', fookFactoryTokens[5]);
                localStorage.setItem('fook_amount4', fookFactoryTokens[6]);
                localStorage.setItem('fook_token4', fookFactoryTokens[7]);

                setcardFrontButton("Details");
            } else {
                //hide the card and display a message saying it doesn't exist
                setState(false);
                setshowApproveMessages('that fook doesn\'t seem to be in the database...')
                setshowDYKmessage("did you manually type the link? \n\n\n it might be better to copy/paste the link or contact the link owner.")
                showApprovalLayer();
            }





            //assign data variable depending on linknetwork
        } else if (fook_network === "songbird") {

            //if (currentAddress !== null) {
                switchNetworkSGB();
            //} else if (currentAddress === null) {
                //alert(connected);
            //}

            setState(false);
            setshowApproveMessages('songbird network is not available yet')
            setshowDYKmessage("did you know? \n\n\n a canary network is designed to provide both real world environment testing as well as prevent mainnet clutter/confusion (i.e. multiple contracts with same name, data, etc.")
            showApprovalLayer();

            const fookCardDataSGB = await useFactorySGBSignN.FookCardData(fook_number);
            if (+fook_number === +fookCardDataSGB.fookNumber) {

                //split the concatenated strings
                const fookFactoryTitles = fookCardDataSGB.fookTitles.split("|");
                const fookFactoryTokens = fookCardDataSGB.fookTokens.split("|");
                const fookFactoryLinks = fookCardDataSGB.fookLinks.split("|");

                //____________________________________________
                //fill card from local storage if seeking the same card (i.e. refresh)
                localStorage.setItem('fook_name', fookFactoryTitles[0]);
                localStorage.setItem('fook_title', fookFactoryTitles[1]);
                localStorage.setItem('fook_recipient', "address: \n" + fookCardDataSGB.fookOwner);
                localStorage.setItem('fook_contract', fookCardDataSGB.fookContract);
                localStorage.setItem('fook_description', fookCardDataSGB.fookDescription);

                if (fookFactoryLinks[0] === '') {
                    localStorage.setItem('fook_imagelink', 'https://storage.googleapis.com/ff-bucket-files/Files/animated-FookPromo.gif');
                    localStorage.setItem('fook_imagesize', "contain");
                } else {
                    localStorage.setItem('fook_imagelink', fookFactoryLinks[0]);
                    localStorage.setItem('fook_imagesize', "auto");
                }

                localStorage.setItem('fook_website', fookFactoryLinks[1]);
                localStorage.setItem('fook_contentlink', fookFactoryLinks[2]);

                localStorage.setItem('fook_amount1', fookFactoryTokens[0]);
                localStorage.setItem('fook_token1', fookFactoryTokens[1]);
                localStorage.setItem('fook_amount2', fookFactoryTokens[2]);
                localStorage.setItem('fook_token2', fookFactoryTokens[3]);
                localStorage.setItem('fook_amount3', fookFactoryTokens[4]);
                localStorage.setItem('fook_token3', fookFactoryTokens[5]);
                localStorage.setItem('fook_amount4', fookFactoryTokens[6]);
                localStorage.setItem('fook_token4', fookFactoryTokens[7]);

                setcardFrontButton("Details");
            } else {
                //hide the card and display a message saying it doesn't exist
                setState(false);
                setshowApproveMessages('that fook doesn\'t seem to be in the database...')
                setshowDYKmessage("did you manually type the link? \n\n\n it might be better to copy/paste the link or contact the link owner.")
                showApprovalLayer();
            }




            //assign data variable depending on linknetwork
        } else if (fook_network === "flare") {

            //if (connected !== null) {
                switchNetworkFLR();
            //} else if (currentAddress === null) {
                //alert(connected);
            //}

            setState(false);
            setshowApproveMessages('flare network is not available yet')
            setshowDYKmessage("did you know? \n\n\n a canary network is designed to provide both real world environment testing as well as prevent mainnet clutter/confusion (i.e. multiple contracts with same name, data, etc.")
            showApprovalLayer();


            const fookCardDataFLR = await useFactoryFLRSignN.FookCardData(fook_number);
            if (+fook_number === +fookCardDataFLR.fookNumber) {

                //split the concatenated strings
                const fookFactoryTitles = fookCardDataFLR.fookTitles.split("|");
                const fookFactoryTokens = fookCardDataFLR.fookTokens.split("|");
                const fookFactoryLinks = fookCardDataFLR.fookLinks.split("|");
                //____________________________________________
                //fill card from local storage if seeking the same card (i.e. refresh)
                localStorage.setItem('fook_name', fookFactoryTitles[0]);
                localStorage.setItem('fook_title', fookFactoryTitles[1]);
                localStorage.setItem('fook_recipient', "address: \n" + fookCardDataFLR.fookOwner);
                localStorage.setItem('fook_contract', fookCardDataFLR.fookContract);
                localStorage.setItem('fook_description', fookCardDataFLR.fookDescription);

                if (fookFactoryLinks[0] === '') {
                    localStorage.setItem('fook_imagelink', 'https://storage.googleapis.com/ff-bucket-files/Files/animated-FookPromo.gif');
                    localStorage.setItem('fook_imagesize', "contain");
                } else {
                    localStorage.setItem('fook_imagelink', fookFactoryLinks[0]);
                    localStorage.setItem('fook_imagesize', "auto");
                }

                localStorage.setItem('fook_website', fookFactoryLinks[1]);
                localStorage.setItem('fook_contentlink', fookFactoryLinks[2]);

                localStorage.setItem('fook_amount1', fookFactoryTokens[0]);
                localStorage.setItem('fook_token1', fookFactoryTokens[1]);
                localStorage.setItem('fook_amount2', fookFactoryTokens[2]);
                localStorage.setItem('fook_token2', fookFactoryTokens[3]);
                localStorage.setItem('fook_amount3', fookFactoryTokens[4]);
                localStorage.setItem('fook_token3', fookFactoryTokens[5]);
                localStorage.setItem('fook_amount4', fookFactoryTokens[6]);
                localStorage.setItem('fook_token4', fookFactoryTokens[7]);

                setcardFrontButton("Details");
            } else {
                //hide the card and display a message saying it doesn't exist
                setState(false);
                setshowApproveMessages('that fook doesn\'t seem to be in the database...')
                setshowDYKmessage("did you manually type the link? \n\n\n it might be better to copy/paste the link or contact the link owner.")
                showApprovalLayer();
            }


        } else {
            //reset the local variable for network switches (so they only show briefly when switched)
            localStorage.setItem('chainNameCard', '');

            if (localStorage.getItem('help-fook_title') === null) {
                setcardTitle("title of your fook appears here \n[max 58 characters]");
            } else {
                setcardTitle(localStorage.getItem('help-fook_title'));
            }
            if (localStorage.getItem('help-fook_name') === null) {
                setcardName('fook name');
            } else {
                setcardName(localStorage.getItem('help-fook_name'));
            }
            //cardButtonText only shows on help screen to explain things 'above'
            if (localStorage.getItem('help-fook_title') === "title of your fook appears here \n[max 58 characters]") {
                setcardButtonText('[above] provide custom, one click buttons that include a preset token and amount \n [scroll below]');
            } else {
                setcardButtonText('');
            }

            if (localStorage.getItem('help-fook_recipient') === null) {
                setcardRecipient('address where the crypto is sent appears here');
            } else {
                setcardRecipient(localStorage.getItem('help-fook_recipient'));
            }
            if (localStorage.getItem('help-fook_description') === null) {
                setcardDescription('the description details you enter appear here \n[max 1000 characters]');
            } else {
                setcardDescription(localStorage.getItem('help-fook_description'));
            }
            if (localStorage.getItem('help-fook_website') === null) {
                setcardWebsite('website you provide appears here \n[optional: max 1000 characters]');
            } else {
                setcardWebsite(localStorage.getItem('help-fook_website'));
            }

            //tokens - setup the help overlay and use as a verify overlay
            if (localStorage.getItem('help-fook_token1') === null) {
                setcardToken1('WSGB');
            } else {
                setcardToken1(localStorage.getItem('help-fook_token1'));
            }
            if (localStorage.getItem('help-fook_token2') === null) {
                setcardToken2('FOOKS');
            } else {
                setcardToken2(localStorage.getItem('help-fook_token2'));
            }
            if (localStorage.getItem('help-fook_token3') === null) {
                setcardToken3('WSGB');
            } else {
                setcardToken3(localStorage.getItem('help-fook_token3'));
            }
            if (localStorage.getItem('help-fook_amount1') === null) {
                setcardAmount1('1000');
            } else {
                setcardAmount1(localStorage.getItem('help-fook_amount1'));
            }
            if (localStorage.getItem('help-fook_amount2') === null) {
                setcardAmount2('20000');
            } else {
                setcardAmount2(localStorage.getItem('help-fook_amount2'));
            }
            if (localStorage.getItem('help-fook_amount3') === null) {
                setcardAmount3('1000');
            } else {
                setcardAmount3(localStorage.getItem('help-fook_amount3'));
            }
            //image - setup the help overlay and use as a verify overlay
            if (localStorage.getItem('help-fook_imagelink') === '') {
                setfookImageLink('https://storage.googleapis.com/ff-bucket-files/Files/animated-FookPromo.gif');
                setfookImageSize("contain");
            } else {
                setfookImageLink(localStorage.getItem('help-fook_imagelink'));
                setfookImageSize("auto");
            }

            setcardFrontButton("Details");
        }
    };



    //________________________________________________________________________________________
    //RENDER THE CARD COMPONENT
    const CardComponent = () => {
        useEffect(() => {
            //console.log("mounted");
            //fetchData();
            //alert(fookImageSize);
        }, []);

        //return <div>Hello Word</div>;
        return <div className="card">

            <input type="checkbox" id="card3" className="more" />
            <div className="content">

                <div className="front"
                    style={{
                        backgroundImage: `url(${fookImageLink})`,
                        backgroundPosition: "center",
                        backgroundSize: `${fookImageSize}`,
                        backgroundRepeat: "no-repeat",
                        transition: "all 0.5s ease-in-out"
                    }}>

                    <div className="inner" >
                        <h2>{cardName}</h2>
                        <div className="rating">
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                        </div>
                        <label htmlFor="card3" className="button" aria-hidden="true">
                            {cardFrontButton}
                        </label>
                    </div>
                </div>




                <div className="back">
                    <div className="inner">

                        <div onClick={() => sendButton1()} className="tokens">
                            <span>{cardAmount1}</span>
                        </div>
                        <div className="token-symbols">
                            <span className="material-symbols-outlined">{cardToken1}</span>
                        </div>
                        <div onClick={() => sendButton2()} className="tokens">
                            <span>{cardAmount2}</span>
                        </div>
                        <div className="token-symbols">
                            <span className="material-symbols-outlined">{cardToken2}</span>
                        </div>
                        <div onClick={() => sendButton3()} className="tokens">
                            <span>{cardAmount3}</span>
                        </div>
                        <div className="token-symbols">
                            <span className="material-symbols-outlined">{cardToken3}</span>
                        </div>


                        <div className="description">
                            <p align="center">{cardButtonText}</p>
                            <p align="center">{cardWebsite}</p>
                            <p align="center">____________________</p>
                            <p align="center">{cardRecipient}</p>
                            <p align="center">____________________</p>
                            <p>{cardDescription}</p>
                        </div>
                        <div className="title">
                            <p>{cardTitle}</p></div>
                        <label htmlFor="card3" className="button return" aria-hidden="true">
                            FRONT
                        </label>
                    </div>
                </div>
            </div>




            <br /><br /><br />

            <div>
                <a style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
                    href={transactionLink} target="_blank" rel="noreferrer">{transactionHash}</a>
            </div>


        </div>
    };



    //________________________________________________________________________________________
    //HTML

    return (
        <div style={{ display: '' }} className="FookCard">

            <div className='chainName'>{chainNameCard}</div>

            <div style={{ zIndex: zIndexState }} className="back-Mainoverlay" className={`back-Mainoverlay ${showApproveButton ? 'show' : 'hide'}`}>
                <div id="approval-objects" >
                    <div className="approveMessage">{showApproveMessages}</div>
                    <br />
                    <div className="payButtons" onClick={() => hideApprovalLayer()}>GO BACK</div>
                    <div className="DYKmessage">{showDYKmessage}</div>
                </div>
            </div>

            <div >
                <button style={{ display: 'none' }} onClick={() => setState(!state)}>Toggle State</button>
                {state ? <CardComponent /> : <></>}
            </div>






        </div>
    );
}
export default FookCard;
